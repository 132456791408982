import React from 'react';
import {useParams} from "react-router-dom";
import VolunteerForm from "../../components/PagesForm/VolunteerForm";

function Edit(props) {
  const {id} = useParams()
    return (
        <VolunteerForm
            isEdit={true}
            id={id}
        />
    );
}

export default Edit;