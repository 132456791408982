import React, { useEffect, useState } from "react";
import VisitService from "../../services/VisitService";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";
import { useFetch } from "../../hooks/useFetch";
import { Query } from "../../helpers/querybuilder";

const Visits = () => {
  const visitService = new VisitService();
  const authService = new AuthService();
  const user = authService.getUserData();

  const { data, loading, fetchData } = useFetch();

  const [visits, setVisits] = useState([]);

  const fetchVisits = () => {
    const query = new Query();
    const url = query
      .for("visits")
      .params({ qb: "true" })
      .includes("client", "volunteer")
      .select("id", "date_of_visiting", "client_id", "volunteer_id", "length")
      .sort("-date_of_visiting")
      .get();

    fetchData(url);
  };

  useEffect(() => {
    setVisits(data);
  }, [data]);

  const removeVisit = (id) => {
    visitService
      .destroy(id)

      .then(() => fetchVisits())
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchVisits();
  }, []);

  if (loading) {
    return (
      <>
        <div className="container py-5">
          <h2>Kõikide külastuse tabel</h2>
          <hr />
          <p>Külastuste andmeid laetakse või uuendatakse...</p>
        </div>
      </>
    );
  }

  return (
    <>
      {roleCheck(user.roles, [
        "super_admin",
        "admin",
        "coordinator",
        "volunteer",
      ]) ? (
        <div className="container py-5">
          <h2>Kõikide külastuste tabel</h2>
          <hr />
          <div className="justify-between flex flex-wrap py-2">
            <Button cta="Lisa külastus" href="/visits/add" />
          </div>
          <table className="table-fixed w-full">
            <thead>
              <tr className="bg-indigo-500 p-4">
                {/*todo translation estonian*/}
                <th>Abivajaja</th>
                <th>Vabatahtlik</th>
                <th>Külastuse kuupäev</th>
                <th>Külastuse aeg</th>
                <th>Muuda</th>
                {roleCheck(user.roles, ["super_admin", "coordinator"]) && (
                  <th>Kustuta külastus</th>
                )}
              </tr>
            </thead>
            <tbody>
              {visits &&
                visits.map((visit) => {
                  return (
                    <tr className="hover:bg-indigo-100" key={visit.id}>
                      <td className="p-2 border">
                        {visit?.client?.name + " " + visit?.client?.last_name}
                      </td>
                      <td className="p-2 border">
                        {visit?.volunteer?.name +
                          " " +
                          visit?.volunteer?.last_name}
                      </td>
                      <td className="p-2 border">{visit.date_of_visiting}</td>
                      <td className="p-2 border">{visit.length}</td>
                      <td className="p-2 border text-right">
                        <Link to={`/visits/${visit.id}/edit`}> Muuda</Link>
                      </td>
                      {roleCheck(user.roles, [
                        "super_admin",
                        "coordinator",
                      ]) && (
                        <td className="p-2 border text-right">
                          <button
                            className="bg-red-500 p-2 text-white"
                            onClick={() => removeVisit(visit.id)}
                          >
                            Kustuta
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <h2>Puuduvad õigused andmete vaatamiseks</h2>
      )}
    </>
  );
};

export default Visits;
