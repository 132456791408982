import React from 'react';
import {useParams} from "react-router-dom";
import CountyForm from "../../components/PagesForm/CountyForm";

function CountyEdit() {
    const {id} = useParams()
    return (
        <CountyForm
            isEdit={true}
            id={id}
        />
    );
}

export default CountyEdit;