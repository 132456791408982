import React from "react";

import FormSpreeForm from "../../components/Survey/FormSpreeForm";

import { FormspreeProvider } from "@formspree/react";
import { useParams } from "react-router-dom";

const SurveyWithFormSpree = () => {
  const { key } = useParams();

  return (
    <div className="container py-5">
      <FormspreeProvider project="1980277748710505635">
        <FormSpreeForm surveyKey={key} />
      </FormspreeProvider>
    </div>
  );
};

export default SurveyWithFormSpree;
