import React from "react";
import { useParams } from "react-router-dom";

import FormspreeSubmissions from "./FormspreeSubmissions";

import forms from "../../formspree.json";

const SubmissionSingle = () => {
  const formIds = Object.keys(forms.forms);

  const { surveyId } = useParams();

  const getSurveyCredentials = (id) => {
    switch (id) {
      case "client_survey_6months_new":
        return {
          hash: process.env.REACT_APP_CLIENT_6_NEW,
          api: process.env.REACT_APP_CLIENT_6_API_NEW,
        };

      case "client_survey_6months":
        return {
          hash: process.env.REACT_APP_CLIENT_6,
          api: process.env.REACT_APP_CLIENT_6_API,
        };

      case "client_survey_final":
        return {
          hash: process.env.REACT_APP_CLIENT_FIN,
          api: process.env.REACT_APP_CLIENT_FIN_API,
        };

      case "volunteer_6months":
        return {
          hash: process.env.REACT_APP_VOLUNTEER_6,
          api: process.env.REACT_APP_VOLUNTEER_6_API,
        };

      case "volunteer_final":
        return {
          hash: process.env.REACT_APP_VOLUNTEER_FIN,
          api: process.env.REACT_APP_VOLUNTEER_FIN_API,
        };

      default:
        return;
    }
  };

  if (!formIds.includes(surveyId))
    return (
      <div className="container py-5">
        <h2>VIGA: Puudub selle ID-ga küsitlus</h2>
      </div>
    );

  return (
    <FormspreeSubmissions
      surveyId={surveyId}
      credentials={getSurveyCredentials(surveyId)}
    />
  );
};

export default SubmissionSingle;
