import React from 'react';
import {useParams} from "react-router-dom";
import SurveyForm from "../../components/PagesForm/SurveyForm";

function FillQuestionnaire() {
    const {id} = useParams();
    return (
        <SurveyForm surveyId={id} answerMode={false}/>
    );
}

export default FillQuestionnaire;