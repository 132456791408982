import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/login";
//import RegisterPage from "./pages/register";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";

import VolunteerAdd from "./pages/volunteers/add";
import Volunteers from "./pages/volunteers";
import VolunteerShow from "./pages/volunteers/show";
import VolunteerEdit from "./pages/volunteers/edit";
import GenerateVolunteersList from "./pages/volunteers/generate-list";

import PrivateAuth from "./components/Auth/PrivateAuth";

import Clients from "./pages/clients";
import ClientAdd from "./pages/clients/add";
import ClientShow from "./pages/clients/show";
import ClientEdit from "./pages/clients/edit";

import Visits from "./pages/visits/index";
import VisitAdd from "./pages/visits/add";
import VisitEdit from "./pages/visits/edit";

import VisitPrices from "./pages/visitPrices/index";
import VisitPriceAdd from "./pages/visitPrices/add";
import VisitPriceEdit from "./pages/visitPrices/edit";

import LocalGovernments from "./pages/localGovernments/index";
import LocalGovernmentAdd from "./pages/localGovernments/add";
import LocalGovernmentEdit from "./pages/localGovernments/edit";

import Counties from "./pages/counties";
import CountyAdd from "./pages/counties/add";
import CountyEdit from "./pages/counties/edit";

import Reports from "./pages/reports";
import ReportsDatatable from "./pages/reports/Datatable";

import DocumentsIndex from "./pages/documents";

import SuperAdmins from "./pages/superadmins/index";
import SuperAdminAdd from "./pages/superadmins/add";
import SuperAdminEdit from "./pages/superadmins/edit";
import UserSingle from "./pages/superadmins/show";

import ClientSemiannual from "./pages/questionnaire/ClientSemiannual";
import VolunteerSemiannual from "./pages/questionnaire/VolunteerSemiannual";
import FillQuestionnaire from "./pages/questionnaire/fillQuestionnaire";
import ListQuestionnaire from "./pages/questionnaire/listQuestionnaire";

import SurveyWithFormSpree from "./pages/questionnaire/SurveyWithFormSpree";

import SurveyAnswers from "./pages/surveyAnswers";
import SurveyAnswerShow from "./pages/surveyAnswers/show";

import UsersWorksheet from "./pages/worksheets/users";
import ClientsWorksheet from "./pages/worksheets/clients";

import MainLayout from "./components/layouts/MainLayout";
import FormspreeSubmissions from "./pages/submissions/FormspreeSubmissions";
import SubmissionSingle from "./pages/submissions";

const CustomRouting = () => {
  return (
    <>
      <Router>
        <MainLayout>
          <Switch>
            <PrivateAuth exact path="/clients">
              <Clients />
            </PrivateAuth>
            <PrivateAuth exact path="/clients/add">
              <ClientAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/clients/:id">
              <ClientShow />
            </PrivateAuth>
            <PrivateAuth exact path="/clients/:id/edit">
              <ClientEdit />
            </PrivateAuth>

            <PrivateAuth exact path="/volunteers">
              <Volunteers />
            </PrivateAuth>
            <PrivateAuth exact path="/volunteers/add">
              <VolunteerAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/volunteers/:id">
              <VolunteerShow />
            </PrivateAuth>
            <PrivateAuth exact path="/volunteers/:id/edit">
              <VolunteerEdit />
            </PrivateAuth>
            <PrivateAuth exact path="/volunteers/generate-list">
              <GenerateVolunteersList />
            </PrivateAuth>

            <PrivateAuth exact path="/local_governments">
              <LocalGovernments />
            </PrivateAuth>
            <PrivateAuth exact path="/local_governments/add">
              <LocalGovernmentAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/local_governments/:id/edit">
              <LocalGovernmentEdit />
            </PrivateAuth>

            <PrivateAuth exact path="/counties">
              <Counties />
            </PrivateAuth>
            <PrivateAuth exact path="/counties/add">
              <CountyAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/counties/:id/edit">
              <CountyEdit />
            </PrivateAuth>

            <PrivateAuth exact path="/visits">
              <Visits />
            </PrivateAuth>
            <PrivateAuth exact path="/visits/add">
              <VisitAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/visits/:id/edit">
              <VisitEdit />
            </PrivateAuth>

            <PrivateAuth exact path="/visit_prices">
              <VisitPrices />
            </PrivateAuth>
            <PrivateAuth exact path="/visit_prices/add">
              <VisitPriceAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/visit_prices/:id/edit">
              <VisitPriceEdit />
            </PrivateAuth>

            <PrivateAuth exact path="/reports">
              <Reports />
            </PrivateAuth>
            <PrivateAuth exact path="/reports/datatable">
              <ReportsDatatable />
            </PrivateAuth>

            <PrivateAuth exact path="/documents">
              <DocumentsIndex />
            </PrivateAuth>

            <PrivateAuth exact path="/super_admins">
              <SuperAdmins />
            </PrivateAuth>
            <PrivateAuth exact path="/super_admins/add">
              <SuperAdminAdd />
            </PrivateAuth>
            <PrivateAuth exact path="/super_admins/:id/edit">
              <SuperAdminEdit />
            </PrivateAuth>
            <PrivateAuth exact path="/super_admins/:id/">
              <UserSingle />
            </PrivateAuth>

            <PrivateAuth exact path="/questionnaire/semiannual/client">
              <ClientSemiannual />
            </PrivateAuth>
            <PrivateAuth exact path="/questionnaire/semiannual/volunteer">
              <VolunteerSemiannual />
            </PrivateAuth>

            <PrivateAuth exact path="/questionnaires">
              <ListQuestionnaire />
            </PrivateAuth>
            <PrivateAuth exact path="/questionnaires/fill/:id">
              <FillQuestionnaire />
            </PrivateAuth>

            <PrivateAuth exact path="/survey/:key">
              <SurveyWithFormSpree />
            </PrivateAuth>

            <PrivateAuth exact path="/survey_answers">
              <SurveyAnswers />
            </PrivateAuth>
            <PrivateAuth exact path="/survey_answers/:id">
              <SurveyAnswerShow />
            </PrivateAuth>

            <PrivateAuth exact path="/worksheets/volunteers">
              <UsersWorksheet />
            </PrivateAuth>
            <PrivateAuth exact path="/worksheets/clients">
              <ClientsWorksheet />
            </PrivateAuth>

            <PrivateAuth exact path="/submissions/formspree">
              <FormspreeSubmissions />
            </PrivateAuth>

            <PrivateAuth path="/submissions/formspree/:surveyId">
              <SubmissionSingle />
            </PrivateAuth>

            <PrivateAuth exact path="/">
              <Dashboard />
            </PrivateAuth>
            <PrivateAuth exact path="/dashboard">
              <Dashboard />
            </PrivateAuth>

            <Route exact path="/login">
              <LoginPage />
            </Route>
            {/*<Route exact path="/register">
              <RegisterPage />
            </Route>*/}
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
          </Switch>
        </MainLayout>
      </Router>
    </>
  );
};

export default CustomRouting;
