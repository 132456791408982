import React, { useEffect, useState } from "react";
import DocumentService from "../../services/DocumentService";
import Input from "../../components/Form/Input";
import InputSelect from "../../components/Form/InputSelect";
import { useForm } from "react-hook-form";
import CountyService from "../../services/CountyService";
import LocalGovernmentService from "../../services/LocalGovernmentService";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { getDocumentTypeText } from "../../services/utils";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

const DocumentsIndex = () => {
  const {
    register,
    handleSubmit,
    getValues,
    // setValue,
    formState: { errors },
  } = useForm();
  const authService = new AuthService();
  const documentService = new DocumentService();
  const countyService = new CountyService();
  const localGovernmentService = new LocalGovernmentService();

  const user = authService.getUserData();

  const [documents, setDocuments] = useState([]);
  const [counties, setCounties] = useState([]);
  const [localGovernments, setLocalGovernments] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCustomParamsForFilters = () => {
    let filters = getValues();
    return `&start_date=${filters.start_date}
            &end_date=${filters.end_date}
            &type=${filters.type}
            &local_government_id=${filters.local_government_id}
            &county_id=${filters.county_id}
            &persons=${filters.persons}
            `;
  };

  const fetchDocuments = () => {
    setLoading(true);
    documentService
      .getAll({
        includes: ["documentable"],
        sorts: ["-updated_at"],
        custom: getCustomParamsForFilters(),
      })
      .then((response) => {
        setDocuments(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const fetchCounties = () => {
    countyService
      .getAll()
      .then((res) => {
        const items = res.data.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
        setCounties(items);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchLocalGovernments = (country_id = null) => {
    let QBConfig = {};
    if (country_id !== null) {
      QBConfig.filters.country_id = country_id;
    }
    localGovernmentService.getAll(QBConfig).then((res) => {
      const items = res.data.data.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
      setLocalGovernments(items);
    });
  };

  const onBulkDownload = () => {
    setLoading(true);
    documentService
      .downloadDocumentsByFilters({
        includes: ["documentable"],
        sorts: ["-updated_at"],
        custom: getCustomParamsForFilters(),
      })
      .then((response) => {
        window.open(response.data.data.zipUrl, "_blank");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDocuments();
    fetchCounties();
    fetchLocalGovernments();
  }, []);

  return (
    <>
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) ? (
        <div>
          {loading ? (
            <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          ) : (
            <div className="container py-5">
              <h2>Dokumentide leht</h2>

              <hr />

              <div className="container">
                <form>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-5">
                    <div>
                      <Input
                        id="start_date"
                        type="date"
                        label="Date From"
                        register={register}
                        error={errors}
                        errorMessage=""
                      />
                    </div>
                    <div>
                      <Input
                        id="end_date"
                        type="date"
                        label="Date To"
                        register={register}
                        error={errors}
                        errorMessage=""
                      />
                    </div>
                    <div>
                      <InputSelect
                        id="county_id"
                        label="Maakond"
                        register={register}
                        error={errors}
                        errorMessage=""
                        options={counties}
                      />
                    </div>
                    <div>
                      <InputSelect
                        id="local_government_id"
                        label="Kohalik omavalitsus"
                        register={register}
                        error={errors}
                        errorMessage=""
                        options={localGovernments}
                      />
                    </div>
                    <div>
                      <Input
                        id="persons"
                        label="Isikute nimed"
                        register={register}
                        error={errors}
                        errorMessage=""
                      />
                    </div>
                    <div>
                      <InputSelect
                        id="type"
                        label="Dokumendi liik"
                        register={register}
                        required={false}
                        error={errors}
                        errorMessage="lorem ipsum"
                        options={[
                          { value: "client_pdf", text: "Klienditoimik" },
                          {
                            value: "volunteer_pdf",
                            text: "Karistusregistri väljavõte",
                          },
                        ]}
                      />
                    </div>

                    <button
                      className="py-2 px-4 bg-green-500"
                      onClick={handleSubmit(fetchDocuments)}
                    >
                      Rakenda filter
                    </button>
                    <button
                      className="py-2 px-4 bg-blue-400"
                      onClick={handleSubmit(onBulkDownload)}
                    >
                      Lae alla dokumendid
                    </button>
                  </div>
                </form>
              </div>
              <table className="table-fixed w-full">
                <thead>
                  <tr className="bg-indigo-500 p-4">
                    <th>Dokumendi Tüüp</th>
                    <th>Vabatahtlikult / Klient</th>
                    <th>Loodud kl</th>
                    <th>Uuendatud kl</th>
                    <th>Lae alla</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.map((document) => {
                      return (
                        <tr className="hover:bg-indigo-100" key={document.id}>
                          <td className="p-2 border">
                            {getDocumentTypeText(document.type)}
                          </td>
                          <td className="p-2 border">
                            {document.documentable?.full_name}
                          </td>
                          <td className="p-2 border">{document.created_at}</td>
                          <td className="p-2 border">{document.updated_at}</td>
                          <td className="p-2 border text-right">
                            <a href={document.temporary_url} target="_blank">
                              Lae alla
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <h2>Puuduvad õigused lehe vaatamiseks</h2>
      )}
    </>
  );
};

export default DocumentsIndex;
