import { useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import ClientService from "../services/ClientService";

export default function Profile({ profileData }) {
  const clientService = new ClientService();
  const [volunteerClients, setVolunteerClients] = useState([]);

  const fetchVolunteerClients = (id) => {
    clientService
      .getAll({
        filters: {
          volunteer_id: [id],
        },
      })
      .then((response) => {
        setVolunteerClients(response.data.data);
      });
  };

  useEffect(() => {
    fetchVolunteerClients(profileData.id);
  }, []);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Vabatahtliku profiil
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Vabatahtliku isiklikud andmed ja ootused
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Nimi</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.full_name}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Isikukood</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.personal_id}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              E-posti aadress
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.email}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Telefon</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.telephone}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Abivajajad</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {volunteerClients &&
                volunteerClients.map((client) => {
                  return (
                    <a
                      href={`/clients/${client.id}`}
                      className="text-green-600"
                    >
                      {client.full_name}
                    </a>
                  );
                })}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Dokumendid</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul
                role="list"
                className="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                {profileData.convicted_temporary_url && (
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {profileData.full_name} karistusregistri väljavõte
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        target="_blank"
                        href={profileData.convicted_temporary_url}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Lae alla
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Leping</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul
                role="list"
                className="border border-gray-200 rounded-md divide-y divide-gray-200"
              >
                {profileData.cooperation_agreement_temporary_url && (
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {profileData.cooperation_agreement_path} Koostööleping
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        target="_blank"
                        href={profileData.cooperation_agreement_temporary_url}
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Lae alla
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Sooviavaldus</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.application && <p>{profileData.application}</p>}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ootused</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {profileData.expectations && <p>{profileData.expectations}</p>}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
