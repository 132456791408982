import React, { useEffect } from "react";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useNotification } from "../../context/errorContext";
import CountyService from "../../services/CountyService";

function CountyForm({ isEdit, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const history = useHistory();
  const countyService = new CountyService();
  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const submitForm = (data) => {
    if (isEdit) {
      countyService
        .update(id, data)
        .then((res) => {
          clearNotifications();
          setNotifications([`${data.name} maakond uuendati`]);
          setNotificationVariant("success");
          history.push("/counties");
        })
        .catch((error) => {
          console.error("Viga!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      countyService
        .create(data)
        .then((res) => {
          clearNotifications();
          setNotifications([`${data.name} maakond loodi`]);
          setNotificationVariant("success");
          history.push("/counties");
        })
        .catch((error) => {
          console.error("Viga!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchCounty = (id) => {
    countyService
      .getSingle(id)
      .then((res) => {
        let fields = res.data.data;

        setValue("name", fields.name);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/counties");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchCounty(id);
    }
  }, [id]);

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <InputCard title="County">
            <Input
              label="Name"
              id="name"
              register={register}
              required
              error={errors}
            />
          </InputCard>
        </Card>
        <hr className="py-5" />
        <div className="flex justify-center py-5 space-x-4">
          <button className="bg-green-400 py-4 px-6" type="submit">
            Salvesta
          </button>
          <button className="bg-red-400 py-4 px-6">Katkesta</button>
        </div>
      </form>
    </div>
  );
}

export default CountyForm;
