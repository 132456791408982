import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SurveyAnswerService from "../../services/SurveyAnswerService";

const SurveyAnswers = () => {
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const surveyAnswerService = new SurveyAnswerService();

  const fetchSurveyAnswers = () => {
    surveyAnswerService
      .getAll({
        includes: ["survey", "user"],
      })
      .then((response) => {
        setSurveyAnswers(response.data.data);
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
      });
  };

  useEffect(() => {
    fetchSurveyAnswers();
  }, []);

  return (
    <div className="container py-5">
      <div className="py-5">
        <h2>Küsitluste vastused</h2>
      </div>

      <hr />

      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            <th>Küsitluse tüüp</th>
            <th>Isik</th>
            <th>Täitja</th>
            <th>Uuendatud</th>
            <th>Loodud</th>
            <th>Vaata</th>
          </tr>
        </thead>
        <tbody>
          {surveyAnswers.length > 0 &&
            surveyAnswers.map((surveyAnswer) => {
              return (
                <tr className="hover:bg-indigo-100" key={surveyAnswer.id}>
                  <td className="p-2 border">{surveyAnswer?.survey?.name}</td>
                  <td className="p-2 border">{surveyAnswer.user.full_name}</td>
                  <td className="p-2 border">{surveyAnswer.filled_id}</td>
                  <td className="p-2 border">{surveyAnswer.updated_at}</td>
                  <td className="p-2 border">{surveyAnswer.created_at}</td>
                  <td className="p-2 border text-right">
                    <Link to={`/survey_answers/${surveyAnswer.id}`}>
                      {" "}
                      Vaata vastuseid
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SurveyAnswers;
