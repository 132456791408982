import React from "react";

const Form = ({ action, method, children }) => {
  const submitForm = (e) => {
    e.preventDefault();
  };
  return <form onSubmit={submitForm}>{children}</form>;
};

export default Form;
