import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import SurveyForm from "../../components/PagesForm/SurveyForm";
import SurveyAnswerService from "../../services/SurveyAnswerService";

function SurveyAnswerShow() {
    const {id} = useParams();

    return (
        <>
            <SurveyForm answerMode={true} surveyAnswerId={id}/>
        </>
    );
}

export default SurveyAnswerShow;