import React from "react";
import DashboardAction from "../../components/DashboardAction";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";
import forms from "../../formspree.json";

const SurveyLinks = () => {
  const authService = new AuthService();
  const user = authService.getUserData();

  const formKeys = Object.keys(forms.forms);

  return (
    <>
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) ? (
        <div className="container py-5">
          <h2>Küsitluste tulemused</h2>
          <div className="py-5">
            <div className="py-10 border-b">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {formKeys.map((surveyId) => {
                  const title = forms.forms[surveyId].name;

                  // TODO: Remove if applied new form
                  if (surveyId === "client_survey_final_new") {
                    return;
                  }
                  return (
                    <DashboardAction
                      name={title}
                      route={`/submissions/formspree/${surveyId}`}
                      key={surveyId}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2>Puuduvad õigused lehe vaatamiseks</h2>
      )}
    </>
  );
};

export default SurveyLinks;
