import React from "react";
import VisitForm from "../../components/PagesForm/VisitForm";
import {useParams} from "react-router-dom";

const VisitEdit = () => {
    const {id} = useParams()
    return (
        <VisitForm
            isEdit={true}
            id={id}
        />
    );
};

export default VisitEdit;
