import React, { useEffect, useState } from "react";
import Input from "../Form/Input";
import InputSelect from "../Form/InputSelect";
import { useForm } from "react-hook-form";
import CountyService from "../../services/CountyService";
import LocalGovernmentService from "../../services/LocalGovernmentService";
import ReportService from "../../services/ReportService";
import { useNotification } from "../../context/errorContext";
import { getReportTypes } from "../../services/utils";

const ReportsGenerate = ({ fetchReports }) => {
  const {
    register,
    handleSubmit,
    watch,
    // setValue,
    formState: { errors },
  } = useForm();
  const { clearNotifications, setNotifications, setNotificationVariant } =
      useNotification();

  const reportService = new ReportService();
  const countyService = new CountyService();
  const localGovernmentService = new LocalGovernmentService();
  const initialReportTypes = getReportTypes();

  const [counties, setCounties] = useState([]);
  const [localGovernments, setLocalGovernments] = useState([]);

  const [filteredGovernments, setFilteredGovernments] = useState([]);

  const activeCounty = watch("county_id");

  useEffect(() => {
    if (activeCounty) {
      let filteredGovernments = localGovernments.filter((government) => {
        return government.county === +activeCounty;
      });
      setFilteredGovernments(filteredGovernments);
    } else {
      setFilteredGovernments(localGovernments);
    }
  }, [activeCounty, localGovernments]);

  const fetchCounties = () => {
    countyService
        .getAll()
        .then((res) => {
          const items = res.data.data.map((item) => {
            return {
              text: item.name,
              value: item.id,
            };
          });
          setCounties(items);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const fetchLocalGovernments = () => {
    localGovernmentService.getAll().then((res) => {
      const items = res.data.data.map((item) => {
        return {
          text: item.name,
          value: item.id,
          county: item.county_id,
        };
      });
      setLocalGovernments(items);
    });
  };

  const onSubmit = (data) => {
    reportService
        .create(data)
        .then((res) => {
          clearNotifications();
          setNotifications(["Raport edukalt genereeritud"]);
          setNotificationVariant("success");
          fetchReports();
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            clearNotifications();
            setNotifications(error);
            setNotificationVariant("danger");
          }
          console.error(error);
        });
  };

  useEffect(() => {
    fetchCounties();
    fetchLocalGovernments();
  }, []);

  return (
      <div className="container flex gap-4">
        <form>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 py-5">
            <div>
              <Input
                  id="start_date"
                  type="date"
                  label="Alguskuupäev"
                  register={register}
                  error={errors}
                  errorMessage=""
              ></Input>
            </div>
            <div>
              <Input
                  id="end_date"
                  type="date"
                  label="Lõppkuupäev"
                  register={register}
                  error={errors}
                  errorMessage=""
              ></Input>
            </div>
            <div>
              <InputSelect
                  id="county_id"
                  label="Maakond"
                  register={register}
                  error={errors}
                  errorMessage=""
                  options={counties}
              ></InputSelect>
            </div>
            <div>
              <InputSelect
                  id="local_government_id"
                  label="Kohalik omavalitsus"
                  register={register}
                  error={errors}
                  errorMessage=""
                  options={filteredGovernments}
              ></InputSelect>
            </div>
            <div>
              <Input
                  id="persons"
                  label="Isikute nimed"
                  register={register}
                  error={errors}
                  errorMessage=""
              ></Input>
            </div>
            <div>
              <InputSelect
                  id="report_type"
                  label="Aruande tüüp"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Vali raporti tüüp"
                  options={initialReportTypes}
              />
            </div>
            <div>
              <div className="mb-2">
                <label htmlFor="generate_without_personal_ids">
                  Laiendatud andmetable (ilma isikukoodideta)
                </label>
              </div>
              <div>
                <input
                    type="checkbox"
                    className="form-checkbox h-6 w-6"
                    id="generate_without_personal_ids"
                    {...register("generate_without_personal_ids")}
                />
              </div>
            </div>

            <button
                className="py-2 px-4 bg-green-500"
                onClick={handleSubmit(onSubmit)}
            >
              Genereeri raport
            </button>
          </div>
        </form>
      </div>
  );
};

export default ReportsGenerate;
