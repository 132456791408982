import React from "react";

const Card = ({ children }) => {
  return (
    <div className="bg-gray-50 border rounded-lg border-gray-500">
      {children}
    </div>
  );
};

export default Card;
