import React, { useEffect, useState } from "react";
import SurveyService from "../../services/SurveyService";
import SurveyAnswerService from "../../services/SurveyAnswerService";
import { useForm } from "react-hook-form";
import InputCheckbox from "../Form/InputCheckbox";
import InputTextarea from "../Form/InputTextarea";
import InputSelect from "../Form/InputSelect";
import Input from "../Form/Input";
import ButtonSubmit from "../ButtonSubmit";
import { useNotification } from "../../context/errorContext";
import { useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import ClientService from "../../services/ClientService";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

function SurveyForm({ surveyId, surveyAnswerId, answerMode = false }) {
  const surveyService = new SurveyService();
  const surveyAnswerService = new SurveyAnswerService();
  const authService = new AuthService();
  const history = useHistory();

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  console.error(errors);

  const [survey, setSurvey] = useState({});
  const [surveyLoading, setSurveyLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const userService = new UserService();
  const clientService = new ClientService();
  const user = authService.getUserData();

  const fetchUsers = () => {
    if (survey.type === "volunteer") {
      userService
        .getAll({ includes: ["roles"] })
        .then((response) => {
          const allUsers = response.data.data;
          const volunteers = allUsers
            .filter((user) => user.roles[0].name === "volunteer")
            .map((user) => {
              return { value: user.id, text: user.full_name };
            });
          setUsers(volunteers);
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
        });
    }

    if (survey.type === "client") {
      clientService
        .getAll()
        .then((response) => {
          const allUsers = response.data.data;
          const volunteers = allUsers.map((user) => {
            return { value: user.id, text: user.full_name };
          });
          setUsers(volunteers);
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
        });
    }
  };

  useEffect(() => {
    if (roleCheck(user.roles, ["volunteer"]) && survey.type === "volunteer") {
      setValue("filled_id", user.userInfo.id);
    } else {
      fetchUsers();
    }
  }, [survey]);

  const fetchSurvey = (id) => {
    setSurveyLoading(true);
    surveyService
      .getSingle(id, {
        includes: ["questions", "questions.options"],
      })
      .then((res) => {
        setSurvey(res.data.data);
      })
      .finally(() => setSurveyLoading(false));
  };

  const getAnswerWithSurvey = (id) => {
    surveyAnswerService
      .getAnswerWithSurvey(id)
      .then((res) => {
        setSurvey(res.data.data);
      })
      .finally(() => setSurveyLoading(false));
  };

  const submitForm = (data) => {
    surveyAnswerService
      .create({
        survey,
        answer: data,
      })
      .then((res) => {
        clearNotifications();
        setNotifications([`Questionnaire completed successfully`]);
        setNotificationVariant("success");
        history.push("/");
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
        clearNotifications();
        setNotifications(error.response.data.errors);
        setNotificationVariant("danger");
      });
  };

  useEffect(() => {
    if (answerMode) {
      getAnswerWithSurvey(surveyAnswerId);
    } else {
      fetchSurvey(surveyId);
    }
  }, []);

  if (!user) return <p>Loading...</p>;

  return (
    <>
      {!surveyLoading && (
        <>
          <div className="container py-5">
            <h2 className="text-center mb-5">{survey.name}</h2>
            {answerMode && (
              <h6 className="text-right mb-6">
                Person filling out the questionnaire:{" "}
                {survey.userAnswer.user.full_name}
              </h6>
            )}
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="w-1/2 mb-4">
                {roleCheck(user.roles, ["volunteer"]) &&
                survey.type === "volunteer" ? (
                  <p>Täidetav kasutaja: {user.userInfo.full_name}</p>
                ) : (
                  <InputSelect
                    label="Täidetav kasutaja"
                    id="filled_id"
                    options={users}
                    required
                    register={register}
                    error=""
                    errorMessage=""
                  />
                )}
              </div>

              {survey.questions &&
                survey.questions.map((question) => {
                  switch (question.type) {
                    case "multi_select":
                      return (
                        <fieldset
                          className="grid grid-cols-1 mb-5"
                          key={question.id}
                        >
                          <div>
                            <h4 className="mb-2">{question.text}</h4>
                            {question.description && (
                              <div className="3-3/4">
                                <p className="mb-2">{question.description}</p>
                              </div>
                            )}
                          </div>
                          {question.options &&
                            question.options.map((option) => {
                              return (
                                <InputCheckbox
                                  key={option.id}
                                  label={option.text}
                                  value={"true"}
                                  checked={
                                    answerMode
                                      ? option.userAnswer.is_selected === 1
                                      : false
                                  }
                                  id={`questions[${question.id}][options][${option.id}]`}
                                  type="checkbox"
                                  register={register}
                                  error=""
                                  errorMessage=""
                                  disabled={answerMode}
                                  answerMode={answerMode}
                                />
                              );
                            })}

                          <div className="w-3/4">
                            <InputTextarea
                              label="muul viisil, kuidas?"
                              rows="4"
                              id={`questions[${question.id}][else]`}
                              register={register}
                              error=""
                              errorMessage=""
                              disabled={answerMode}
                              value={answerMode ? question.userAnswer.else : ""}
                              answerMode={answerMode}
                            />
                          </div>
                        </fieldset>
                      );
                    case "single_select":
                      return (
                        <fieldset
                          className="grid grid-cols-1 mb-5"
                          key={question.id}
                        >
                          <div>
                            <h4 className="mb-2">{question.text}</h4>
                            {question.description && (
                              <div className="w-3/4">
                                <p className="mb-2">{question.description}</p>
                              </div>
                            )}
                          </div>
                          {question.options && (
                            <div>
                              {question.options.map((option, i) => {
                                let additionalAttributes = {};
                                if (answerMode) {
                                  additionalAttributes.checked =
                                    option.userAnswer.is_selected === 1;
                                }
                                return (
                                  <div key={i}>
                                    <input
                                      type="radio"
                                      id={`questions[${question.id}][checked]-${i}`}
                                      name={`questions[${question.id}][checked]`}
                                      value={option.id}
                                      disabled={answerMode}
                                      {...additionalAttributes}
                                      {...register(
                                        `questions[${question.id}][checked]`,
                                        { required: true }
                                      )}
                                    />
                                    <label
                                      htmlFor={`questions[${question.id}][checked]-${i}`}
                                    >
                                      {option.text}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          <div className="w-3/4">
                            <InputTextarea
                              label="muul viisil, kuidas?"
                              rows="4"
                              id={`questions[${question.id}][else]`}
                              register={register}
                              error=""
                              errorMessage=""
                              disabled={answerMode}
                              value={answerMode ? question.userAnswer.else : ""}
                              answerMode={answerMode}
                            />
                          </div>
                        </fieldset>
                      );
                    case "short_text":
                      return (
                        <fieldset
                          className="grid grid-cols-1 mb-5"
                          key={question.id}
                        >
                          <div>
                            <h4 className="mb-2">{question.text}</h4>
                            {question.description && (
                              <div className="w-3/4">
                                <p className="mb-2">{question.description}</p>
                              </div>
                            )}
                            <div className="w-3/4">
                              <Input
                                label=""
                                id={`questions[${question.id}][text]`}
                                register={register}
                                error=""
                                errorMessage=""
                                value={
                                  answerMode ? question.userAnswer.text : ""
                                }
                                disabled={answerMode}
                              />
                            </div>
                          </div>
                        </fieldset>
                      );
                    case "long_text":
                      return (
                        <fieldset
                          className="grid grid-cols-1 mb-5"
                          key={question.id}
                        >
                          <div>
                            <h4 className="mb-2">{question.text}</h4>
                            {question.description && (
                              <div className="w-3/4">
                                <p className="mb-2">{question.description}</p>
                              </div>
                            )}
                            <div className="w-3/4">
                              <InputTextarea
                                label=" "
                                rows="4"
                                id={`questions[${question.id}][text]`}
                                register={register}
                                error=""
                                errorMessage=""
                                disabled={answerMode}
                                value={
                                  answerMode ? question.userAnswer.text : ""
                                }
                                answerMode={answerMode}
                              />
                            </div>
                          </div>
                        </fieldset>
                      );
                  }
                })}

              {!answerMode && (
                <ButtonSubmit text="Esita küsitluse andmed" type="submit" />
              )}
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default SurveyForm;
