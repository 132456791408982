import React, { useEffect, useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../components/Form/Input";
import { Alert } from "@mui/material";
import axios from "axios";

const ResetPassword = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm();
  const [serverError, setServerError] = useState(null);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const onSubmit = async (data) => {
    try {
      await axios.post("/api/password/reset-password", {
        token: data.token,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
      });
      history.push("/login");
    } catch (error) {
      setServerError(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    setValue("token", query.get("token"));
    setValue("email", query.get("email"));
  }, [setValue, query]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
          {serverError && <Alert severity="error">{serverError}</Alert>}
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="rounded-md shadow-sm -space-y-px">
            <Input
              type="hidden"
              id="token"
              register={register}
              required
              error={errors}
            />
            <Input
              type="hidden"
              id="email"
              register={register}
              required
              error={errors}
            />
            <Input
              type="password"
              id="password"
              label="Password"
              register={register}
              required
              error={errors}
            />
            <Input
              type="password"
              id="password_confirmation"
              label="Confirm Password"
              register={register}
              required
              error={errors}
            />
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
