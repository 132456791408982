import { useState, useEffect, useMemo } from "react";
import Button from "../../components/Button";

import Table from "../../components/Table";
import { roleCheck } from "../../services/utils";

import { useFetchData } from "../../hooks/useFetchData";
import { useAuth } from "../../context/AuthContext";
import { Query } from "../../helpers/querybuilder";

const Clients = () => {
  const [countyFilter, setCountyFilter] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);

  const { getUserData } = useAuth();
  const user = getUserData();

  const query = new Query();
  const url = query
    .for("clients")
    .params({ qb: "true" })
    .includes("localGovernment", "localGovernment.county")
    .get();

  const { data: clients, isLoading } = useFetchData("clients", url);
  const { data: counties } = useFetchData("counties", "/counties");

  const columns = useMemo(
    () => [
      {
        Header: "Nimi",
        accessor: "full_name",
      },
      {
        Header: "Isikukood",
        accessor: "personal_id",
      },
      {
        Header: "Maakond",
        accessor: "local_government.county.name",
      },
      {
        Header: "Kohalik omavalitsus",
        accessor: "local_government.name",
      },
      {
        Header: "Tegevuste algus",
        accessor: "beginning_of_activities",
      },
      {
        Header: "Tegevuse lõpp",
        accessor: "end_of_activities",
      },
      {
        Header: "Muuda",
        accessor: "id",
      },
    ],
    []
  );

  const filterClients = () => {
    if (!clients) {
      setFilteredClients([]);
      return;
    }
    const newFilteredClients = clients.filter(
      (client) => client.local_government.county_id === countyFilter
    );
    setFilteredClients(newFilteredClients);
  };

  useEffect(() => {
    filterClients();
  }, [countyFilter]);

  if (isLoading) {
    return <h2>Laadimine...</h2>;
  }
  return (
    <div className="container py-5">
      <h2>Abivajajad</h2>

      <hr />
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) && (
        <div className="justify-between flex flex-wrap py-2 my-2">
          <Button cta="Lisa abivajaja" href="/clients/add" />
        </div>
      )}

      <hr />
      {counties && roleCheck(user.roles, ["super_admin", "admin"]) && (
        <div className="counties flex space-x-2 py-2">
          {counties.map((county) => {
            if (
              clients.some(
                (client) => client.local_government.county_id === county.id
              )
            ) {
              return (
                <button
                  key={county.id}
                  className="p-2 bg-green-400 hover:bg-green-300 rounded"
                  onClick={() => setCountyFilter(county.id)}
                >
                  {county.name}
                </button>
              );
            }
            return null;
          })}
          <button
            className="p-2 bg-yellow-400 hover:bg-yellow-300 rounded"
            onClick={() => setCountyFilter(null)}
          >
            Tühista filtrid
          </button>
        </div>
      )}

      <hr className="py-5" />
      <h3 className="mb-2">
        Tabelis kasutajaid:{" "}
        {filteredClients && filteredClients.length
          ? filteredClients.length
          : clients && clients.length}
      </h3>

      <Table
        mode="clients"
        columns={columns}
        data={filteredClients.length ? filteredClients : clients}
      />
    </div>
  );
};

export default Clients;
