import React from "react";
import VisitForm from "../../components/PagesForm/VisitForm";

const VisitAdd = () => {
    return (
        <VisitForm
            isEdit={false}
        />
    );
};

export default VisitAdd;
