import React from "react";

const InputCard = ({ title, description, children }) => {
  return (
    <div className="p-4">
      <div
        className={`md:grid ${
          title ? "md:grid-cols-3" : "md:grid-cols-2"
        } md:gap-6`}
      >
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-600">{description}</p>
          </div>
        </div>
        <div className="md:col-span-2">
          <div className="px-4 sm:px-0">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default InputCard;
