import { useState, useEffect, useMemo } from "react";
import Button from "../../components/Button";
import { roleCheck } from "../../services/utils";
import Table from "../../components/Table";
import AuthService from "../../services/AuthService";
import { useFetch } from "../../hooks/useFetch";
import { Query } from "../../helpers/querybuilder";

const Volunteers = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Nimi",
        accessor: "full_name",
      },
      {
        Header: "Isikukood",
        accessor: "personal_id",
      },
      {
        Header: "Maakond",
        accessor: "local_government.county.name",
      },
      {
        Header: "Kohalik omavalitsus",
        accessor: "local_government.name",
      },
      {
        Header: "Muuda",
        accessor: "id",
      },
    ],
    []
  );

  const [volunteers, setVolunteers] = useState([]);
  const [counties, setCounties] = useState([]);
  const [countyFilter, setCountyFilter] = useState(null);
  const [filteredVolunteers, setFilteredVolunteers] = useState([]);
  const authService = new AuthService();
  const user = authService.getUserData();

  const { data: userData, loading, fetchData } = useFetch();
  const {
    data: countiesData,
    loading: countiesLoading,
    fetchData: fetchCountiesData,
  } = useFetch();

  const fetchUsers = () => {
    const query = new Query();
    const url = query
      .for("users")
      .includes("localGovernment.county")
      .params({ roles: "volunteer", page_type: "volunteer" })
      .get();

    fetchData(url + "&qb=true");
  };

  const fetchCounties = () => {
    const query = new Query();
    const url = query.for("counties").get();

    fetchCountiesData(url);
  };

  useEffect(() => {
    fetchCounties();
    fetchUsers();
  }, []);

  useEffect(() => {
    setCounties(countiesData);
    setVolunteers(userData);
  }, [userData, countiesData]);

  useEffect(() => {
    const filterVolunteers = () => {
      setFilteredVolunteers([]);
      volunteers
        .filter(
          (volunteer) => volunteer.local_government.county_id === countyFilter
        )
        .map((volunteer) => {
          setFilteredVolunteers((prevState) => [...prevState, volunteer]);
        });
    };

    filterVolunteers();
  }, [countyFilter]);

  if (loading || countiesLoading) return <h2>Loading...</h2>;

  return (
    <div className="container py-5">
      <h2>Vabatahtlikud</h2>
      <hr />
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) && (
        <div className="justify-between flex flex-wrap py-2">
          <Button cta="Lisa vabatahtlik" href="/volunteers/add" />
        </div>
      )}
      {(roleCheck(user.roles, ["super_admin", "admin"]) ||
        user.userInfo.full_name === "Helen Metsmaa") && (
        <div className="counties flex space-x-2 py-2">
          {volunteers &&
            counties.map((county) => {
              if (
                volunteers.some(
                  (volunteer) =>
                    volunteer.local_government.county_id === county.id
                )
              ) {
                return (
                  <button
                    key={county.id}
                    className="p-2 bg-green-400 hover:bg-green-300 rounded"
                    onClick={() => setCountyFilter(county.id)}
                  >
                    {county.name}
                  </button>
                );
              }
              return;
            })}
          <button
            className="p-2 bg-yellow-400 hover:bg-yellow-300 rounded"
            onClick={() => setCountyFilter(null)}
          >
            Tühista filtrid
          </button>
        </div>
      )}
      <Table
        mode="volunteers"
        columns={columns}
        data={filteredVolunteers.length ? filteredVolunteers : volunteers}
      />
    </div>
  );
};

export default Volunteers;
