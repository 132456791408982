import React from "react";
import DashboardAction from "../../components/DashboardAction";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

const Index = () => {
  const authService = new AuthService();
  const user = authService.getUserData();
  return (
    <>
      {roleCheck(user.roles, ["super_admin", "admin", "coordinator", "som"]) ? (
        <div className="container py-5">
          <h2>Raportite leht</h2>
          <div className="py-5">
            <div className="py-10 border-b">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <DashboardAction
                  name="Raportite genereerimine (andmetabel, KOV, külastused)"
                  route={"/reports/datatable"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2>Puuduvad õigused lehe vaatamiseks</h2>
      )}
    </>
  );
};

export default Index;
