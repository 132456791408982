import React from "react";

const InputSelect = ({
  label,
  id,
  options,
  inline,
  register,
  required,
  error,
  errorMessage,
  isMultiple = false,
  disabled = false,
  emptyOption = true
}) => {
  const formValues = emptyOption ? [{ value: "", text: "" }, ...options] : options;
  return (
    <div
      className={`col-span-6 sm:col-span-3 ${
        inline && "flex space-x-8 items-center py-4"
      }`}
    >
      <label
        htmlFor={label}
        className={`block text-sm font-medium text-gray-700 ${
          inline && "w-1/2"
        }`}
      >
        {label}
      </label>
      <select
        name={isMultiple ? `${id}[]` : id}
        id={id}
        multiple={isMultiple}
        autoComplete="given-name"
        disabled={disabled}
        className={`${
          error[id] && "border-red-500"
        } mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
          disabled && "text-gray-500"
        }`}
        {...register(id, { required })}
      >
        {formValues.map((option, i) => (
          <option key={i} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      {error[id] && <small className="text-red-500">{errorMessage}</small>}
    </div>
  );
};

export default InputSelect;
