import axios from "axios";
import { isArray } from "@craco/craco/lib/utils";

export const createApiClient = () => {
  const apiUrl = getApiUrl();
  const axiosInstance = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    xsrfHeaderName: "X-XSRF-TOKEN",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  return axiosInstance;
};

export const queryBuilder = (config = {}) => {
  let q = "qb=true";

  if (config.filters) {
    for (let key in config.filters) {
      q += `&filter[${key}]=${config.filters[key].join(",")}`;
    }
  }

  if (config.sorts && config.sorts.length > 0) {
    q += `&sort=${config.sorts.join(",")}`;
  }

  if (config.includes && config.includes.length > 0) {
    q += `&include=${config.includes.join(",")}`;
  }

  if (config.appends && config.appends.length > 0) {
    q += `&append=${config.appends.join(",")}`;
  }

  if (config.fields) {
    for (let key in config.fields) {
      q += `&field[${key}]=${config.fields[key].join(",")}`;
    }
  }

  if (config.custom && config.custom.length > 0) {
    q += config.custom;
  }
  return q;
};

export const getApiUrl = () =>
  process.env.REACT_APP_API_URL || "http://vabatahtlikud-2021-api.test";

export const permissionCheck = (currentUserPermissions, permissions) => {
  if (!isArray(permissions)) {
    permissions = [permissions];
  }
  return (
    currentUserPermissions.some((currentUserPermission) =>
      permissions.includes(currentUserPermission)
    ) !== -1
  );
};

export const roleCheck = (currentUserRoles, roles) => {
  if (!isArray(roles)) {
    roles = [roles];
  }
  return currentUserRoles.some((currentUserRole) =>
    roles.includes(currentUserRole)
  );
};

export const getReportTypes = () => {
  return [
    { value: "client_based_v1", text: "Andmetabel" },
    { value: "visit_based_v1", text: "KOV Väljavõte" },
    { value: "visit_based_v3", text: "KOV Väljavõte vabatahtlikega" },
    { value: "visit_based_v2", text: "Külastuste väljavõte" },
  ];
};
export const getReportTypeText = (reportType) => {
  let reportTypes = getReportTypes();
  let findReportType = reportTypes.find((item) => item.value === reportType);
  if (findReportType) {
    return findReportType.text;
  } else {
    return "unknown type";
  }
};

export const getDocumentTypeText = (documentType) => {
  if (documentType === "client_pdf") {
    return "Klienditoimik";
  } else if (documentType === "volunteer_pdf") {
    return "Vabatahtliku karistusregistri väljavõte";
  } else if (documentType === "client_acceptance_document") {
    return "Kliendi nõusolek";
  } else {
    return "";
  }
};
