import React from 'react';
import LocalGovernmentForm from "../../components/PagesForm/LocalGovernmentForm";
import {useParams} from "react-router-dom";

function LocalGovernmentEdit() {
    const {id} = useParams()
    return (
        <LocalGovernmentForm
            isEdit={true}
            id={id}
        />
    );
}

export default LocalGovernmentEdit;