import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import LocalGovernmentService from "../../services/LocalGovernmentService";

const LocalGovernments = () => {
  const [localGovernments, setLocalGovernments] = useState([]);
  const localGovernmentService = new LocalGovernmentService();

  const fetchLocalGovernments = () => {
    localGovernmentService
      .getAll({
        includes: ["county"],
      })
      .then((response) => {
        setLocalGovernments(response.data.data);
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
      });
  };

  const removeLocalGovernment = (id) => {
    localGovernmentService
      .destroy(id)
      .then(() => fetchLocalGovernments())
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchLocalGovernments();
  }, []);

  return (
    <div className="container py-5">
      <h2>Kohalikud omavalitsused</h2>
      <hr />
      <div className="justify-between flex flex-wrap py-6">
        <Button cta="Lisa kohalik omavalitsus" href="/local_governments/add" />
      </div>

      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            <th>Nimi</th>
            <th>Omavalitsuse nimi</th>
            <th>Muuda</th>
            <th>Kustuta</th>
          </tr>
        </thead>
        <tbody>
          {localGovernments &&
            localGovernments.map((localGovernment) => {
              return (
                <tr className="hover:bg-indigo-100" key={localGovernment.id}>
                  <td className="p-2 border">{localGovernment.name}</td>
                  <td className="p-2 border">
                    {localGovernment?.county?.name}
                  </td>
                  <td className="p-2 border text-right">
                    <Link to={`/local_governments/${localGovernment.id}/edit`}>
                      {" "}
                      Muuda
                    </Link>
                  </td>
                  <td className="p-2 border text-right">
                    <button
                      className="bg-red-500 p-2 text-white"
                      onClick={() => removeLocalGovernment(localGovernment.id)}
                    >
                      Kustuta
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default LocalGovernments;
