import React from "react";
import VolunteerForm from "../../components/PagesForm/VolunteerForm";
import {useParams} from "react-router-dom";

const UserEdit = () => {
    const {id} = useParams()
    return <VolunteerForm isEdit={true} isAddVolunteer={false} id={id}/>;
};

export default UserEdit;
