import Navigation from "../Nav/Navigation";
import { useNotification } from "../../context/errorContext";
import NotificationModal from "../NotificationModal";

const MainLayout = ({ children }) => {
  const { notifications, notificationVariant } = useNotification();

  return (
    <div className="grid grid-flow-row">
      <Navigation />
      {notifications && (
        <NotificationModal
          messages={notifications}
          variant={notificationVariant}
        />
      )}

      <main className="self-stretch">{children}</main>
    </div>
  );
};

export default MainLayout;
