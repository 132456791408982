import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import VisitPriceService from "../../services/VisitPriceService";

const Visits = () => {
  const visitPriceService = new VisitPriceService();

  const [visitPrices, setVisitPrices] = useState([]);

  const fetchVisitPrices = () => {
    visitPriceService
      .getAll({
        sorts: ["-updated_at"],
      })
      .then((response) => {
        setVisitPrices(response.data.data);
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
      });
  };

  useEffect(() => {
    fetchVisitPrices();
  }, []);

  return (
    <div className="container py-5">
      <h2>Hindade tabel</h2>
      <small>
        Hinnad lisatakse kuupäevast alates edasi kuni uue kuupäevani. Raportites
        arvestatakse hindade kalkuleerimist nende vahemike järgi.
      </small>
      <hr />
      <div className="justify-between flex flex-wrap py-2">
        <Button cta="Lisa hind" href="/visit_prices/add" />
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            <th>Perioodi alguskuupäev</th>
            <th>Hind</th>
            <th>Muuda</th>
          </tr>
        </thead>
        <tbody>
          {visitPrices &&
            visitPrices.map((visitPrice) => {
              return (
                <tr className="hover:bg-indigo-100" key={visitPrice.id}>
                  <td className="p-2 border">{visitPrice.beginning_date}</td>
                  <td className="p-2 border">{visitPrice.price}</td>
                  <td className="p-2 border text-right">
                    <Link to={`/visit_prices/${visitPrice.id}/edit`}>
                      {" "}
                      Muuda
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Visits;
