import React from "react";
import { useHistory } from "react-router-dom";

const Button = ({ cta, href, ...rest }) => {
  const history = useHistory();

  return (
    <button
      onClick={() => history.push(href)}
      className="p-2 bg-green-400 hover:bg-green-300 rounded"
    >
      {cta}
    </button>
  );
};

export default Button;
