import React from "react";
import DashboardAction from "../../components/DashboardAction";

function ListQuestionnaire(props) {
  return (
    <div className="container py-5">
      <h2>Küsitlused</h2>
      <div className="py-5">
        <div className="py-10 border-b">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <DashboardAction
              name="Kliendi vaheküsimustik UUENDATUD"
              route={`/survey/client_survey_6months_new`}
            />
            <DashboardAction
              name="Kliendi lõpuküsimustik"
              route={`/survey/client_survey_final`}
            />
            <DashboardAction
              name="Vabatahtliku vaheküsimustik"
              route={`/survey/volunteer_6months`}
            />
            <DashboardAction
              name="Vabatahtliku lõpuküsimustik"
              route={`/survey/volunteer_final`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListQuestionnaire;
