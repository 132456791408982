import React, { useState, useRef, useContext } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import Logos from "../components/Logos";

const LoginPage = () => {
  const history = useHistory();

  // Extracting authenticated state and login function from useAuthContext
  const { authenticated, login } = useContext(AuthContext);

  const emailRef = useRef();
  const passwordRef = useRef();
  const rememberRef = useRef();

  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Reset the error state

    await login(emailRef.current.value, passwordRef.current.value)
      .then(() => {
        history.push("/");
      })
      .catch((err) => {
        // First, try to get the specific validation error messages
        let specificErrorMessage = err.response?.data?.errors
          ? Object.values(err.response.data.errors)[0][0] // Take the first error message of the first error field
          : null;

        // Then, fall back to the general error message, or use a generic message
        setError(
          specificErrorMessage ||
            err.response?.data?.message ||
            "An error occurred. Please try again."
        );
      });
  };

  if (authenticated) {
    history.push("/dashboard");
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-16">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Logi keskkonda sisse
          </h2>
        </div>
        {error && <Alert severity="error">{error}</Alert>}{" "}
        {/* Display error message */}
        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                E-mail
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="E-mail"
                ref={emailRef}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Parool
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Parool"
                ref={passwordRef}
              />
            </div>
          </div>

          <div>
            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleLogin}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Logi sisse
            </button>
          </div>
          {/* TODO: Fix forgot password */}
          {/* <div className="flex items-center justify-between">
            <div className="text-sm">
              <button
                onClick={() => history.push("/forgot-password")}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Unustasid parooli?
              </button>
            </div>
          </div> */}
        </form>
        <Logos />
      </div>
    </div>
  );
};

export default LoginPage;
