import React from "react";

const ButtonSubmit = ({ text, ...rest }) => {
  return (
    <button {...rest} className="p-2 bg-green-400 hover:bg-green-300 rounded">
      {text}
    </button>
  );
};

export default ButtonSubmit;
