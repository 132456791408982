import ApiService from "./ApiService";

export default class VisitService extends ApiService
{
    collection = 'visits'

    globalIncludes = [
        'client',
        'volunteer'
    ]
}