import { useEffect } from "react";
import { useFormspree } from "../../hooks/useFormspee";
import forms from "../../formspree.json";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";

const FormspreeSubmissions = ({ surveyId, credentials }) => {
  const { hash, api } = credentials;

  const headers = Object.keys(forms.forms[surveyId].fields);

  const headerEntries = Object.entries(forms.forms[surveyId].fields);

  const questions = headerEntries.map((entry) => {
    return entry[1].question;
  });

  const surveyTitle = forms?.forms[surveyId]?.name;

  const { data: submissions, loading, getSubmissions } = useFormspree();

  const downloadSubmissions = () => {
    const submissionsArray = [];

    submissions.map((submission) => {
      const singleSubmission = [];

      headers.map((field) => {
        if (field === "client") {
          return singleSubmission.push(submission.client_name);
        } else if (field === "volunteer") {
          return singleSubmission.push(submission.volunteer_name);
        } else {
          return singleSubmission.push(submission[field]);
        }
      });

      return submissionsArray.push(singleSubmission);
    });

    return submissionsArray;
  };

  const handleDownloadExcel = () => {
    const currentDate = moment().format("YY_MM_DD");

    if (headers && submissions) {
      downloadExcel({
        fileName: `${currentDate}_${surveyId}.xls`,
        sheet: `${surveyTitle}`,
        tablePayload: {
          header: questions,
          body: downloadSubmissions(),
        },
      });
    }
  };

  useEffect(() => {
    getSubmissions(hash, api);
  }, []);

  if (loading) return <p>Loading..</p>;
  if (submissions.length === 0)
    return <p>Antud küsimustikke ei ole täidetud</p>;

  return (
    <div className="container py-5">
      <h2>{surveyTitle}</h2>
      <div className="py-2">
        {" "}
        <button className="bg-green-400 p-2" onClick={handleDownloadExcel}>
          {" "}
          Lae küsitluse tulemused alla{" "}
        </button>
      </div>

      <table className="table-auto w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            {headers.map((header) => (
              <th key={header} className="py-2 text-sm border">
                {forms.forms[surveyId].fields[header].question}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, index) => {
            return (
              <tr key={`${submission.client}${index}`}>
                {headers.map((field) => {
                  if (field === "client")
                    return (
                      <td className="p-1 border text-right" key={field}>
                        {submission.client_name}
                      </td>
                    );
                  if (field === "volunteer")
                    return (
                      <td className="border p-1" key={field}>
                        {submission.volunteer_name}
                      </td>
                    );
                  return (
                    <td className="border p-1 break-words" key={field}>
                      {submission[field]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FormspreeSubmissions;
