import React from "react";

const InputRadio = ({
  id,
  half = true,
  disabled = false,
  register,
  required,
  error = "",
  errorMessage = null,
  items,
                      checked = false,
                      answerMode = false,
    checkedId = 0
}) => {
  let additionalProps = {};
  if (answerMode) {
    additionalProps.checked = checked
  }
  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>
          <input
            type="radio"
            id={`${id}-${i}`}
            name={id}
            value={item.value}
            className={`${error[id] && "border-red-500"} form-radio`}
            disabled={disabled}
            {...additionalProps}
            checked={answerMode ? (item.checked) : false}
            {...register(id, { required })}
          />
          <label htmlFor={`${id}-${i}`}>{item.text}</label>
        </div>
      ))}
      {error[id] && <small className="text-red-500">{errorMessage}</small>}
    </div>
  );
};

export default InputRadio;
