import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputCard from "../../components/Form/InputCard";
import Card from "../../components/Card";
import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";
import { useNotification } from "../../context/errorContext";
import { roleCheck } from "../../services/utils";

import VisitAdd from "../../components/visits/VisitAdd";
import { cli } from "tailwindcss/lib/constants";
import AuthService from "../../services/AuthService";

const ClientSingle = () => {
  const history = useHistory();
  const clientService = new ClientService();
  const userService = new UserService();
  const authService = new AuthService();

  const user = authService.getUserData();

  const [client, setClient] = useState([]);
  const [clientVolunteer, setClientVolunteer] = useState(null);

  const dateObject = new Date(client.date_of_birth);
  const birthDate = dateObject.toLocaleDateString();

  const [showAddVisitForm, setShowAddVisitForm] = useState(false);

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  let { id } = useParams();

  const fetchClient = (id) => {
    clientService
      .getSingle(id, {
        includes: ["visits", "document"],
      })
      .then((res) => {
        setClient(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const destroyClient = (id) => {
    clientService
      .destroy(id)
      .then(() => {
        clearNotifications();
        setNotifications([`Abivajaja ${client.full_name} on kustutatud`]);
        history.push("/clients");
      })
      .catch((error) => {
        console.error(error);
        clearNotifications();
      });
  };

  const fetchVolunteer = (id) => {
    userService.getSingle(id).then((res) => setClientVolunteer(res.data.data));
  };

  useEffect(() => {
    fetchClient(id);
  }, [id]);

  useEffect(() => {
    if (client.volunteer_id) {
      fetchVolunteer(client.volunteer_id);
    }
  }, [client]);

  return (
    <div className="container pb-5">
      <div className="py-5 flex justify-between">
        <div>
          <h2>{`${client.name} ${client.last_name}`}</h2>
          <h3>{client.personal_id ?? birthDate}</h3>
        </div>
        {roleCheck(user.roles, ["super_admin", "admin"]) && (
          <div>
            <button
              className="py-4 px-6 bg-red-400"
              onClick={() => destroyClient(id)}
            >
              Kustuta abivajaja
            </button>
          </div>
        )}
      </div>
      <hr className="py-5" />
      <Card>
        <InputCard title="Isikuandmed ja osalejate üldandmed">
          <table className="table-fixed w-3/4">
            <thead>
              <tr>
                <th className="w-1/3" />
                <th className="w-auto" />
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">ID</td>
                <td className="p-2">{client.id}</td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Eesnimi</td>
                <td className="p-2">{client.name}</td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Perekonnanimi</td>
                <td className="p-2">{client.last_name}</td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Isikukood</td>
                <td className="p-2">
                  {client.personal_id ?? "Isikukood avaldamata"}
                </td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Sugu</td>
                <td className="p-2">{client.sex === "m" ? "Mees" : "Naine"}</td>
              </tr>
              {!client.personal_id && client.date_of_birth && (
                <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                  <td className="p-2 font-semibold">Sünnikuupäev</td>
                  <td className="p-2">{client.date_of_birth}</td>
                </tr>
              )}
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">E-post</td>
                <td className="p-2">
                  {client.email ? (
                    <a href={`mailto:${client.email}`}>{client.email}</a>
                  ) : (
                    "Puudub"
                  )}
                </td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Telefon</td>
                <td className="p-2">
                  {client.telephone ? (
                    <a href={`tel:${client.telephone}`}>{client.telephone}</a>
                  ) : (
                    "Puudub"
                  )}
                </td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">
                  Kodutu / eluasemelt tõrjutu
                </td>
                <td className="p-2">{client.is_homeless ? "Jah" : "Ei"}</td>
              </tr>
              <tr className="odd:bg-indigo-100 even:bg-indigo-50 hover:bg-indigo-200">
                <td className="p-2 font-semibold">Ukraina sõjapõgenik</td>
                <td className="p-2">
                  {client.is_ukraine_refugee === "true" ? "Jah" : "Ei"}
                </td>
              </tr>
            </tbody>
          </table>
        </InputCard>
      </Card>
      <hr className="py-5" />
      {/* <Card>
        <InputCard title="Dokumendid">
          {client.document && (
            <a href={client.document.temporary_url} className="text-green-600">
              Lae alla kliendi nõusolek
            </a>
          )}
        </InputCard>
      </Card> */}
      <hr className="py-5" />
      <Card>
        <InputCard title="Vabatahtlik">
          {clientVolunteer && (
            <a
              href={`/volunteers/${clientVolunteer.id}`}
              className="text-green-600"
            >
              {clientVolunteer.full_name}
            </a>
          )}
        </InputCard>
      </Card>
      <hr className="py-5" />
      <Card>
        <InputCard title="Lisa külastus">
          <button
            className="py-4 px-6 bg-green-400"
            onClick={() => setShowAddVisitForm(!showAddVisitForm)}
          >
            Lisa külastus
          </button>
          {showAddVisitForm && (
            <VisitAdd
              client={client}
              volunteer={4}
              fetchClient={fetchClient}
              toggleShowForm={() => setShowAddVisitForm(!showAddVisitForm)}
            />
          )}
        </InputCard>
      </Card>
      <hr className="py-5" />
      <Card>
        <div className="p-5">
          <h2 className="mb-5">Külastuste nimekiri</h2>
          {client.visits ? (
            <table className="table-fixed w-full">
              <thead>
                <tr>
                  <th className="w-2/12">Kuupäev</th>
                  <th className="w-1/12">Kestus</th>
                  <th className="w-6/12">Kirjeldus</th>
                  <th className="w-3/12">Marsruut</th>
                </tr>
              </thead>
              <tbody>
                {client.visits.map((visit) => {
                  return (
                    <tr key={visit.id}>
                      <td className="p-2 border">{visit.date_of_visiting}</td>
                      <td className="p-2 border">{visit.length} h</td>
                      <td className="p-2 border">{visit.description}</td>
                      <td className="p-2 border">{visit.route}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>Külastusi ei ole registreeritud</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ClientSingle;
