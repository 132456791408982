import { Route, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AuthService from "../../services/AuthService";

const PrivateAuth = ({ children, ...rest }) => {
  const authService = new AuthService();
  const authenticated = authService.isAuthenticated();
  const user = authService.getUserData();
  const history = useHistory();

  if (authenticated && user.roles.length === 0)
    return (
      <div className="container text-center py-10">
        <h2>Viga kasutaja rollis, palun võta ühendust administraatoriga</h2>
      </div>
    );
  if (authenticated === true) return <Route {...rest}>{children}</Route>;
  if (authenticated === false) {
    history.push("/login");
    return null; // Add this line
  }

  if (authenticated === null)
    return (
      <div className="container text-center py-10">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );

  return null; // Change this line from return; to return null;
};

export default PrivateAuth;
