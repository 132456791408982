import React from "react";
import VisitPriceForm from "../../components/PagesForm/VisitPriceForm";

const VisitPriceAdd = () => {
    return (
        <VisitPriceForm
            isEdit={false}
        />
    );
};

export default VisitPriceAdd;
