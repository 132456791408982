import { createContext, useContext, useState } from "react";
import AuthService from "../services/AuthService";
import axios from "axios";
import { getApiUrl } from "../services/utils";
import { useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const authService = new AuthService();
  const user = authService.getUserData();
  const [authenticated, setAuthenticated] = useState(false);
  const client = axios;

  const [config, setConfig] = useState({
    baseURL: getApiUrl(),
    tokenKey: "accessToken",
    userDataKey: "userData",
    tokenType: "Bearer",
    headers: {
      Authorization: null,
    },
  });

  const checkAuthorization = () => {
    const accessToken = getToken();

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      config.headers.Authorization = `${config.tokenType} ${accessToken}`;
      setConfig((prevConfig) => ({
        ...prevConfig,
        headers: {
          ...prevConfig.headers,
          Authorization: `${config.tokenType} ${accessToken}`,
        },
      }));
      setAuthenticated(true);
    } else {
      removeToken();
      removeUserData();
      setAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuthorization();
  }, []);

  const getToken = () => {
    return localStorage.getItem(config.tokenKey);
  };

  const setToken = (token) => {
    localStorage.setItem(config.tokenKey, token);
  };

  const removeToken = () => {
    localStorage.removeItem(config.tokenKey);
  };

  const getUserData = () => {
    return JSON.parse(localStorage.getItem(config.userDataKey));
  };

  const setUserData = (userData) => {
    localStorage.setItem(config.userDataKey, JSON.stringify(userData));
  };

  const removeUserData = () => {
    localStorage.removeItem(config.userDataKey);
  };

  const isAuthenticated = () => {
    return !!getToken();
  };

  const login = async (email, password) => {
    let url = "/api/oauth/login-password-grant";
    let data = {
      email,
      password,
    };
    const response = await client.post(url, data);
    setToken(response.data.access_token);
    await profile();
    setAuthenticated(true);
    return response;
  };

  const profile = async () => {
    let url = "/api/user";
    const response = await client.get(url);
    const user = response.data;
    setUserData(user);
    return response;
  };

  const logout = async () => {
    let url = "/api/oauth/logout";
    const response = await client.delete(url);
    removeToken();
    removeUserData();
    setAuthenticated(false);
    return response.status === 200;
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        user,
        login,
        profile,
        logout,
        setToken,
        removeToken,
        getUserData,
        setUserData,
        removeUserData,
        isAuthenticated,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
