import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import UserService from "../../services/UserService";
import CountyService from "../../services/CountyService";

const SuperAdmins = () => {
  const [users, setUsers] = useState([]);
  const [counties, setCounties] = useState(null);

  const userService = new UserService();
  const countyService = new CountyService();

  const fetchUsers = () => {
    userService
      .getAll({
        custom: `&page_type=super_admin`,
        includes: ["roles"],
      })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
      });
  };

  const fetchCounties = () => {
    countyService
      .getAll()
      .then((response) => {
        setCounties(response.data.data);
      })
      .catch((error) => {
        console.error("County service fails", error.message);
      });
  };

  const getUserCounties = (govIds) => {
    const resp_counties = govIds.map((govId) => {
      const county = counties?.find((county) => county.id === govId);
      return county?.name;
    });
    const response = resp_counties.map((county) => {
      return <p>{county}</p>;
    });

    return response;
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [counties]);

  return (
    <div className="container py-5">
      <h2>Administraatorid, koordinaatorid jt kasutajad</h2>
      <hr />
      <div className="justify-between flex flex-wrap py-2">
        <Button cta="Lisa admin või koordinaator" href="/super_admins/add" />
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            <th>Nimi</th>
            <th>Isikukood</th>
            <th>Roll</th>
            <th>Maakond</th>
            <th>Muuda</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user) => {
              return (
                <tr className="hover:bg-indigo-100" key={user.id}>
                  <td className="p-2 border">{user.full_name}</td>
                  <td className="p-2 border">{user.personal_id}</td>
                  <td className="p-2 border">
                    {user.roles.map((role) => role.name).join(", ")}
                  </td>
                  <td className="p-2 border">
                    {getUserCounties(user.responsible_county_ids)}
                  </td>
                  <td className="p-2 border text-right">
                    <Link to={`/super_admins/${user.id}`}> Vaata</Link>
                    {" / "}
                    <Link to={`/super_admins/${user.id}/edit`}> Muuda</Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SuperAdmins;
