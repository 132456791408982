import { useState, useEffect } from "react";
import Form from "../../components/Form/Form";
import Card from "../../components/Card";
import InputCard from "../../components/Form/InputCard";
import Input from "../../components/Form/Input";
import InputTextarea from "../../components/Form/InputTextarea";
import { useForm } from "react-hook-form";

import { useNotification } from "../../context/errorContext";
import VisitService from "../../services/VisitService";
import AuthService from "../../services/AuthService";

const VisitAdd = ({ client, fetchClient, toggleShowForm }) => {
  const { setNotifications, setNotificationVariant } = useNotification();
  const visitService = new VisitService();
  const authService = new AuthService();

  const user = authService.getUserData();
  const [lengthHours, setlengthHours] = useState(0);
  const [lengthMinutes, setlengthMinutes] = useState(0);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleVisitAdd = (data) => {
    const requestData = {
      ...data,
      client_id: client.id,
      volunteer_id: user.userInfo.id,
    };

    visitService
      .create(requestData)
      .then((response) => {
        toggleShowForm();
        setBlankToFormData();
        fetchClient(client.id);
        setNotifications(["Külastus edukalt lisatud"]);
        setNotificationVariant("success");
      })
      .catch(function (error) {
        setNotifications(["Viga külastuse lisamisel"]);
        setNotificationVariant("danger");
      });
  };

  const setBlankToFormData = () => {
    setValue("date_of_visiting", "");
    setValue("length", "");
    setValue("description", "");
    setValue("route", "");
  };

  const round = (num) => {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  };

  useEffect(() => {
    let calculatedMinutes = round(lengthMinutes / 60);
    let totalTime = +lengthHours + +calculatedMinutes;
    setValue("length", totalTime);
  }, [lengthHours, lengthMinutes]);

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(handleVisitAdd)}>
        <Card>
          <InputCard>
            <h2>Nimi: {`${client.name} ${client.last_name}`}</h2>
            {client.personal_id && <h3>Isikukood: {client.personal_id}</h3>}
            <hr className="py-5" />
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <Input
                  type="date"
                  id="date_of_visiting"
                  label="Külastuse kuupäev"
                  register={register}
                  required
                  error={errors}
                />
              </div>
              <div className="col-span-2">
                <div>
                  <Input
                    type="hidden"
                    label="Külastuse kestus (tundi)"
                    id="length"
                    register={register}
                    required
                    error={errors}
                  />
                  <div className="flex mt-2 gap-x-4">
                    <div className="">
                      <input
                        type="number"
                        label="Külastuse kestus (tundi)"
                        id="hours"
                        min="0"
                        default="0"
                        className="w-16 mr-2"
                        onChange={(e) => setlengthHours(e.target.value)}
                      />
                      <label htmlFor="hours">h</label>
                    </div>
                    <div className="">
                      <input
                        type="number"
                        label="Külastuse kestus (tundi)"
                        id="minutes"
                        min="0"
                        max="59"
                        default="0"
                        className="w-16 mr-2"
                        onChange={(e) => setlengthMinutes(e.target.value)}
                      />
                      <label htmlFor="minutes">min</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <label>Kirjeldus</label>
                <InputTextarea
                  register={register}
                  required
                  id="description"
                  error={errors}
                />
              </div>
              <div className="col-span-2">
                <label>Marsruut</label>
                <Input register={register} id="route" error={errors} />
              </div>
            </div>
          </InputCard>
        </Card>
        <div className="flex justify-center py-5 space-x-4">
          <button className="bg-green-400 py-4 px-6" type="submit">
            Salvesta
          </button>
          <button className="bg-red-400 py-4 px-6">Katkesta</button>
        </div>
      </form>
    </div>
  );
};

export default VisitAdd;
