import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNotification } from "../../context/errorContext";
import { useHistory } from "react-router-dom";
import ClientService from "../../services/ClientService";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import InputSelect from "../Form/InputSelect";
import LocalGovernmentService from "../../services/LocalGovernmentService";
import UserService from "../../services/UserService";

function ClientForm({ isEdit, id }) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const localGovernmentService = new LocalGovernmentService();
  const clientService = new ClientService();
  const userService = new UserService();

  const [isHomeless, setIsHomeless] = useState(false);
  const [personalIdAllowed, setPersonalIdAllowed] = useState(true);
  const [localGovernments, setLocalGovernments] = useState([]);
  const [localVolunteers, setLocalVolunteers] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [governmentId, setGovernmentId] = useState(null);

  const defaultOptions = [
    { value: "yes", text: "Jah" },
    { value: "ei", text: "Ei" },
    { value: "refuse", text: "Keeldus" },
  ];

  const defaultPrefilledOptions = [
    { value: "prefilled", text: "Eeltäidetud sihtrühma alusel" },
    { value: "asked", text: "Küsitud osaleja käest" },
  ];

  const defineVolunteerOptions = localVolunteers.map((volunteer) => {
    return { value: volunteer.id, text: volunteer.full_name };
  });

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  useEffect(() => {
    if (defineVolunteerOptions.length && clientData) {
      setValue("volunteer_id", clientData.volunteer_id);
    }
  });

  const fetchClient = (id) => {
    clientService
      .getSingle(id)
      .then((res) => {
        let fields = res.data.data;
        setClientData(res.data.data);
        setValue("acceptance_description", fields.acceptance_description);
        setValue("beginning_of_activities", fields.beginning_of_activities);
        setValue("education_level", fields.education_level);
        if (fields.end_of_activities !== "null") {
          setValue("email", fields.email);
        }
        if (fields.end_of_activities !== "null") {
          setValue("end_of_activities", fields.end_of_activities);
        }
        setValue(
          "end_of_activities_initiator",
          fields.end_of_activities_initiator
        );
        setValue("is_in_care_institution", fields.is_in_care_institution);
        setValue(
          "is_taking_courses_when_joining",
          fields.is_taking_courses_when_joining
        );
        setValue(
          "is_taking_courses_when_joining_prefilled",
          fields.is_taking_courses_when_joining_prefilled
        );
        setValue("is_working", fields.is_working);
        setValue("is_working_prefilled", fields.is_working_prefilled);
        setValue("last_name", fields.last_name);
        setValue("local_government_id", fields.local_government_id);
        setGovernmentId(fields.local_government_id);
        setValue("name", fields.name);
        setValue("sex", fields.sex);
        setValue("telephone", fields.telephone);
        setValue("date_of_birth", fields.date_of_birth);
        setValue("education_level", fields.education_level);
        setValue("is_disabled", fields.is_disabled);
        setValue("is_immigrant_or_minority", fields.is_immigrant_or_minority);
        setValue(
          "is_living_with_adult_and_children",
          fields.is_living_with_adult_and_children
        );
        setValue("is_living_with_unemployed", fields.is_living_with_unemployed);
        setValue(
          "is_living_with_unemployed_and_children",
          fields.is_living_with_unemployed_and_children
        );
        setValue("is_working", fields.is_working);
        setValue(
          "is_ukraine_refugee",
          fields.is_ukraine_refugee === "true" ? true : false
        );
        setValue("is_working_prefilled", fields.is_working_prefilled);
        setValue("last_name", fields.last_name);
        setValue(
          "other_unfavorable_condition",
          fields.other_unfavorable_condition
        );
        if (fields.personal_id !== "null") {
          setValue("personal_id", fields.personal_id);
        } else {
          console.log("midagi on vale");
        }

        if (fields.date_of_birth) {
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/clients");
        }
      });
  };

  const fetchLocalGovernments = () => {
    localGovernmentService
      .getAll({
        includes: ["county"],
        filters: {
          by_responsible_counties_with_coordinator_role: [true],
        },
      })
      .then((res) => {
        let items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: `${item?.county?.name} / ${item.name}`,
          };
        });
        setLocalGovernments(items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitForm = (data) => {
    setSubmitting(true);
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === "client_acceptance_document") {
        if (value.length > 0) {
          formData.append("client_acceptance_document", value[0]);
        }
      } else {
        formData.append(key, value);
      }
    }

    if (isEdit) {
      clientService
        .update(id, formData)

        .then((response) => {
          clearNotifications();
          setNotifications([
            `Abivajaja ${data.name} ${data.last_name} on edukalt lisatud`,
          ]);
          setNotificationVariant("success");
          history.push("/clients/");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          console.log(error);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      clientService
        .create(formData)
        .then((response) => {
          clearNotifications();
          setNotifications([
            `Abivajaja ${data.name} ${data.last_name} on edukalt lisatud`,
          ]);
          setNotificationVariant("success");
          history.push("/clients/");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchLocalVolunteers = () => {
    if (clientData) {
      userService
        .getAll({
          custom: "&page_type=clientForm",
        })
        .then((response) => {
          setLocalVolunteers(response.data.data);
        });
    }
  };

  useEffect(() => {
    fetchLocalGovernments();
  }, []);

  useEffect(() => {
    fetchLocalVolunteers(governmentId);
  }, [governmentId]);

  useEffect(() => {
    if (isEdit) {
      fetchClient(id);
    }
  }, [id, localGovernments]);

  useEffect(() => {
    setValue("is_working_prefilled", "asked");
    setValue("is_taking_courses_when_joining_prefilled", "asked");
  }, []);

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <InputCard
            title="Isikuandmed"
            description="Lisa isikuandmed abivajale"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Input
                  label="Eesnimi"
                  id="name"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Eesnimi on vajalik"
                />
              </div>
              <div>
                <Input
                  label="Perekonnanimi"
                  id="last_name"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Perekonnanimi on vajalik"
                />
              </div>
              <div>
                <Input
                  disabled={!personalIdAllowed}
                  label="Isikukood"
                  id="personal_id"
                  type="number"
                  register={register}
                  required={!personalIdAllowed ? "" : true}
                  error={errors}
                  errorMessage="Isikukood on vajalik kuniks selle avaldamist pole soovitud - selleks vajuta allolevasse kastikesse"
                />
                <div className="mt-4">
                  <input
                    type="checkbox"
                    name="allowPersonalId"
                    className="form-checkbox"
                    onChange={() => setPersonalIdAllowed(!personalIdAllowed)}
                  />
                  <label htmlFor="allowPersonalId">
                    Ei soovi isikukoodi avaldada
                  </label>
                </div>
              </div>
              <div>
                <InputSelect
                  label="Sugu"
                  options={[
                    { value: "m", text: "Mees" },
                    { value: "n", text: "Naine" },
                  ]}
                  id="sex"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Sugu on vajalik märkida"
                />
              </div>
            </div>
            {!personalIdAllowed && (
              <div className="col-span-2">
                <Input
                  label="Sünnikuupäev"
                  type="date"
                  id="date_of_birth"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Sünnikuupäev on vajalik, kui isikukoodi ei avaldata"
                />
              </div>
            )}
            <div>
              <Input
                label="Telefon"
                type="tel"
                id="telephone"
                register={register}
                required
                error={errors}
                errorMessage="Telefon on vajalik väli"
              />
            </div>
            <div>
              <Input
                label="E-posti aadress"
                type="email"
                id="email"
                register={register}
                error={errors}
                errorMessage="E-posti väli on vigane"
              />
            </div>
            <div>
              <InputSelect
                label="Kohalik omavalitsus"
                options={localGovernments}
                id="local_government_id"
                register={register}
                required
                error={errors}
                errorMessage="Kohalik omavalitsus on vajalik väli"
              />
            </div>

            <div>
              <InputSelect
                label="Kõrgeim omandatud haridustase"
                options={[
                  { value: 0, text: "Algharidus puudub" },
                  { value: 1, text: "Alg- või põhiharidus" },
                  { value: 2, text: "Üld- või kutseharidus" },
                  { value: 3, text: "Kõrgharidus" },
                ]}
                id="education_level"
                register={register}
                required
                error={errors}
                errorMessage="Haridustase on vajalik väli"
              />
            </div>
            <div className="flex items-center py-2">
              <input
                className="mr-2 form-checkbox"
                type="checkbox"
                onChange={() => setIsHomeless(!isHomeless)}
              />
              <label>Klient on kodutu</label>
            </div>
            <div>
              <input
                className="mr-2 form-checkbox"
                type="checkbox"
                name="is_ukraine_refugee"
                id="client_acceptance_document"
                {...register("is_ukraine_refugee")}
              />
              <label>Ukraina sõjapõgenik</label>
            </div>
          </InputCard>
        </Card>
        {!personalIdAllowed && (
          <>
            <hr className="py-5" />
            <Card>
              <InputCard title="Ebasoodsas olukorras olevad osalejad">
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – elab koos mittetöötavate leibkonnaliikmetega"
                    options={defaultOptions}
                    inline
                    id="is_living_with_unemployed"
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  />
                </div>
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – elab koos ülalpeetavate laste ja mittetöötavate leibkonnaliikmetega"
                    id="is_living_with_unemployed_and_children"
                    options={defaultOptions}
                    inline
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  ></InputSelect>
                </div>
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – elab ülalpeetavate laste ja ühe täiskasvanuga leibkonnas"
                    id="is_living_with_adult_and_children"
                    options={defaultOptions}
                    inline
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  ></InputSelect>
                </div>
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – sisserändaja, teise riigi taustaga või rahvusvähemusse kuuluv"
                    id="is_immigrant_or_minority"
                    options={defaultOptions}
                    inline
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  ></InputSelect>
                </div>
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – puue määratud"
                    id="is_disabled"
                    options={defaultOptions}
                    inline
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  ></InputSelect>
                </div>
                <div>
                  <InputSelect
                    label="Tegevuses osaleja – muu ebasoodne olukord"
                    id="other_unfavorable_condition"
                    options={defaultOptions}
                    inline
                    register={register}
                    required
                    error={errors}
                    errorMessage="Ebasoodsas olekus olevad osaleja väljad on vajalikud"
                  ></InputSelect>
                </div>
              </InputCard>
            </Card>
          </>
        )}

        <hr className="py-5" />
        <Card>
          <InputCard title="Tegevustega seotud andmed">
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <Input
                  type="date"
                  label="Tegevuse alustamise kuupäev"
                  id="beginning_of_activities"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Tegevuse alustamise kuupäev on vajalik väli"
                />
              </div>
              <div>
                <Input
                  type="date"
                  label="Tegevuse lõpetamise kuupäev, sh katkestamise"
                  id="end_of_activities"
                  register={register}
                  error={errors}
                  errorMessage="Tegevuse lõpetamise väli on vigane"
                />
              </div>
              {(watch("end_of_activities") ||
                getValues("end_of_activities")) && (
                <div>
                  <div>
                    <InputSelect
                      label="Tegevuse katkestaja"
                      id="end_of_activities_initiator"
                      options={defaultOptions}
                      inline
                      register={register}
                      error={errors}
                      errorMessage="Tegevuse katkestaja on vajalik väli, kui lõpukuupäev on määratud"
                    ></InputSelect>
                  </div>
                </div>
              )}
            </div>
          </InputCard>
        </Card>
        <hr className="py-5" />
        <Card>
          <InputCard title="Õppimine ja osalemine kursustel tegevusega liitumisel">
            <div className="">
              <InputSelect
                label="Õppimine ja osalemine kursustel tegevusega liitumisel"
                id="is_taking_courses_when_joining"
                inline
                options={defaultOptions}
                register={register}
                required
                error={errors}
                errorMessage="Kursustel osalemise väli on vajalik"
              />
            </div>
            <div className="">
              <InputSelect
                label="Õppimine ja osalemine kursustel tegevusega liitumisel: eeltäidetud sihtrühma alusel või küsitud"
                id="is_taking_courses_when_joining_prefilled"
                inline
                options={defaultPrefilledOptions}
                register={register}
                required
                disabled
                default={defaultPrefilledOptions["asked"]}
                error={errors}
                errorMessage="Kursustel osalemise väli on vajalik"
              />
            </div>
          </InputCard>
        </Card>
        <hr className="py-5" />
        <Card>
          <InputCard title="Tööhõive staatus tegevusega liitumisel">
            <div>
              <InputSelect
                label="Tööhõive staatus tegevusega liitumisel"
                id="is_working"
                inline
                options={[
                  { value: null, text: null },
                  { value: "working", text: "Töötav, sh FIE" },
                  {
                    value: "unemployed_short_term",
                    text: "Töötu (kuni 12 kuud)",
                  },
                  {
                    value: "unemployed_long_term",
                    text: "Pikaajaline töötu (üle 12 kuu)",
                  },
                  { value: "not_active", text: "Mitteaktiivne" },
                  { value: "refused", text: "Keeldumine" },
                ]}
                register={register}
                required
                error={errors}
                errorMessage="Tööhõive väli on vajalik"
              />
            </div>
            <div>
              <InputSelect
                label="Tööhõive staatus tegevusega liitumisel: eeltäidetud sihtrühma alusel või küsitud"
                id="is_working_prefilled"
                disabled
                inline
                options={defaultPrefilledOptions}
                register={register}
                required
                error={errors}
                errorMessage="Tööhõive väli on vajalik"
              />
            </div>
          </InputCard>
        </Card>

        <hr className="py-5" />
        <Card>
          <InputCard title="Kliendi nõusolek ja sobivus">
            <div className="grid grid-cols-1 gap-4">
              <div>
                <Input
                  label="Kliendi sobivuse põhjendus"
                  id="acceptance_description"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Kliendi nõusoleku väli on vajalik"
                />
              </div>
              <div>
                <Input
                  label="Klient viibib hoolekandeasutuses"
                  id="is_in_care_institution"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Vajalik väli"
                />
              </div>
            </div>
          </InputCard>
        </Card>
        <hr className="py-5" />
        <Card>
          <InputCard title="Kliendi nõusoleku üleslaadimine">
            <div>
              <input
                type="file"
                name="client_acceptance_document"
                id="client_acceptance_document"
                {...register("client_acceptance_document")}
              />
            </div>
          </InputCard>
        </Card>
        <hr className="py-5" />
        {isEdit && (
          <Card>
            <InputCard title="Määra vabatahtlik">
              <InputSelect
                label="Vabatahtliku nimi"
                id="volunteer_id"
                options={defineVolunteerOptions}
                register={register}
                error={errors}
                errorMessage="Sugu on vajalik märkida"
              />
            </InputCard>
          </Card>
        )}

        <div className="flex justify-center py-5 space-x-4">
          <button
            className="bg-green-400 py-4 px-6"
            type="submit"
            disabled={submitting}
          >
            Salvesta
          </button>
          <button
            className="bg-red-400 py-4 px-6"
            onClick={() => history.push("/clients")}
          >
            Katkesta
          </button>
        </div>
      </form>
    </div>
  );
}

export default ClientForm;
