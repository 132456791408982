import axios from "axios";
import { useState } from "react";

export const useFetch = () => {
  const token = localStorage.getItem("accessToken");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
    withCredentials: true,
    xsrfHeaderName: "X-XSRF-TOKEN",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  });

  const fetchData = async (url) => {
    try {
      console.log("triggered");
      const response = await api.get(url);

      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        console.log(error);
      }
    }
  };

  return { data, loading, fetchData };
};
