import React from "react";

const InputTextarea = ({
  id,
  register,
  required,
  label,
  error,
  errorMessage,
  small,
  rows = "10",
    value='',
                           disabled = false,
                           answerMode = false
}) => {
    let additionalAttributes = {};
    if (answerMode) {
        additionalAttributes.value = value;
        additionalAttributes.disabled = disabled;
    }
  return (
    <>
      <label className="text-gray-700 text-sm ml-5 mb-2" htmlFor={id}>
        {label}
      </label>
      {small && <small className="text-gray-600 my-4">{small}</small>}
      <textarea
    rows={rows}
    {...additionalAttributes}
    className={`form-textarea w-full ${error[id] && "border-red-500"}`}
        {...register(id, {required: errorMessage})}
    />
      {error[id] && <small className="text-red-500">{errorMessage}</small>}
    </>
  );
};

export default InputTextarea;
