import ApiService from "./ApiService";
import {queryBuilder} from "./utils";

export default class DocumentService extends ApiService
{
    collection = 'documents'

    downloadDocumentsByFilters = async (QBConfig) => {
        this.checkCollection();

        let url = `/api/${this.collection}/downloadDocumentsByFilters`;
        QBConfig = this.getQBResult(QBConfig);
        url += `?${queryBuilder(QBConfig)}`;

        return await this.client.get(url);
    }
}