import React from "react";
import DashboardAction from "../components/DashboardAction";

import { permissionCheck, roleCheck } from "../services/utils";
import ListQuestionnaire from "../pages/questionnaire/listQuestionnaire";
// import moment from "moment";
import _ from "lodash";
import AuthService from "../services/AuthService";
import { roles } from "../helpers/constants";

import SurveyLinks from "./submissions/SurveyLinks";
import Logos from "../components/Logos";

const Dashboard = () => {
  const authService = new AuthService();
  const user = authService.getUserData();

  // const { data } = useFetch();
  // console.log("fetchi data", data);

  // const getTimeDifference = () => {
  //   const now = new Date();
  //   const halfYearInMs = 180 * 24 * 60 * 60 * 1000;

  //   const lastSurvey = _.last(user.userInfo.survey_by_filled[0].survey_answers);

  //   const checkRegistrationTime = () => {
  //     const pastTime = new Date(user.created_at);
  //     const timeDiffInMs = now.getTime() - pastTime.getTime();

  //     return timeDiffInMs >= halfYearInMs;
  //   };

  //   if (lastSurvey) {
  //     const pastTime = new Date(
  //       moment(lastSurvey.created_at).format("YYYY-MM-DD")
  //     );
  //     const timeDiffInMs = now.getTime() - pastTime.getTime();

  //     return timeDiffInMs >= halfYearInMs;
  //   }

  //   if (checkRegistrationTime) return true;

  //   return;
  // };

  if (permissionCheck(user.all_permissions, ["add_an_optional"])) {
    //show widget
  }
  if (roleCheck(user.roles, ["super_admin", "admin", "coordinator"])) {
  }
  return (
    <div className="container py-5">
      <div className="user-data">
        <p>
          Sisse logitud kasutaja:{" "}
          {`${user.userInfo.name} ${user.userInfo.last_name}`}
        </p>
        <p>
          Sisse logitud kasutaja roll:{" "}
          {user.roles.map((role) => roles[role] ?? "Puudub")}
        </p>
      </div>
      {/* <div className="my-5">
        {roleCheck(user.roles, ["volunteer"]) && getTimeDifference() && (
          <Notification
            messages={[
              "Sinu viimase küsitluse täitmisest on möödunud rohkem, kui 6 kuud. Palun täida küsitlus",
            ]}
            variant="warning"
          />
        )}
      </div> */}
      {roleCheck(user.roles, [
        "super_admin",
        "admin",
        "coordinator",
        "som",
      ]) && (
        <div className="py-10 border-b">
          <div className="mb-8">
            <h2>Vabatahtlikud</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {Object.values(user.all_permissions).includes(
              "create volunteer"
            ) && (
              <DashboardAction
                name="Lisa vabatahtlik"
                route={"/volunteers/add"}
              />
            )}

            <DashboardAction name="Vabatahtlikud" route={"/volunteers"} />
            {/* {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) && (
              <>
                <DashboardAction
                  name="Genereeri vabatahtlike Excel"
                  route={"/volunteers/generate-list"}
                />
              </>
            )} */}
          </div>
        </div>
      )}

      <div className="py-10 border-b">
        <div className="mb-8">
          <h2>Abivajajad</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <DashboardAction name="Abivajajad" route="/clients" />
          {roleCheck(user.roles, ["super_admin", "admin", "coordinator"]) && (
            <>
              <DashboardAction name="Lisa abivajaja" route="/clients/add" />
            </>
          )}
          {roleCheck(user.roles, ["super_admin", "admin"]) && (
            <DashboardAction name="Lisa külastus" route="/visits/add" />
          )}
        </div>
      </div>
      {roleCheck(user.roles, ["super_admin", "admin"]) && (
        <div className="py-10 border-b">
          <div className="mb-8">
            <h2>Administraator</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <DashboardAction
              name="Halda kõiki peakasutajaid"
              route="/super_admins"
            />
            <DashboardAction name="Lisa kasutaja" route="/super_admins/add" />
            <DashboardAction
              name="Kohalikud omavalitsused"
              route="/local_governments"
            />
            <DashboardAction name="Maakonnad" route="/counties" />
            <DashboardAction
              name="Halda vabatahtlike tunnihindasid"
              route="/visit_prices"
            />
          </div>
        </div>
      )}
      {roleCheck(user.roles, ["super_admin", "admin"]) && (
        <div className="py-10 border-b">
          <div className="mb-8">
            <h2>Töötabelid</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <DashboardAction
              name="Vabatahtlike nimekiri"
              route="/worksheets/volunteers"
            />

            <DashboardAction
              name="Abivajajate nimekiri"
              route="/worksheets/clients"
            />
          </div>
        </div>
      )}

      {roleCheck(user.roles, [
        "admin",
        "super_admin",
        "coordinator",
        "volunteer",
      ]) && <ListQuestionnaire />}

      <SurveyLinks />

      {/* {roleCheck(user.roles, ["admin", "super_admin", "analytic"]) && (
        <div className="py-10 border-b">
          <div className="mb-8">
            <h2>Analüütik</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <DashboardAction
              name="Küsitluste vastused"
              route="/survey_answers"
            />
          </div>
        </div>
      )} */}

      <div className="w-4/5 md:w-1/3">
        <Logos />
      </div>
    </div>
  );
};

export default Dashboard;
