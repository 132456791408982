import React from "react";
import axios from "axios";

const GenerateVolunteersList = () => {
  return (
    <div className="container">
      <button>Genereeri vabatahtlike list</button>
    </div>
  );
};

export default GenerateVolunteersList;
