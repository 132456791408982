import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { useForm } from "@formspree/react";
import questions from "../../formspree.json";
import AuthService from "../../services/AuthService";
import ClientService from "../../services/ClientService";
import UserService from "../../services/UserService";
import { useNotification } from "../../context/errorContext";
import { useHistory } from "react-router-dom";

const RenderCheckboxQuestion = ({ question }) => (
  <Grid item xs={12} style={{ marginBottom: 20 }}>
    <FormControl component="fieldset" className="w-4/5 lg:w-3/">
      <FormLabel component="legend">{question.question}</FormLabel>
      <FormGroup className="pl-5 mb-4" id={question.key}>
        {question.options.map((option, index) => {
          return (
            <FormControlLabel
              key={option.index}
              name={question.key}
              control={<Checkbox />}
              label={option}
              className="my-1"
              value={option}
              id={question.key[index]}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  </Grid>
);
const RenderRadioQuestion = ({ question }) => (
  <Grid item xs={12} style={{ marginBottom: 20 }}>
    <FormControl component="fieldset" className="w-4/5 lg:w-3/">
      <FormLabel component="legend">{question.question}</FormLabel>
      <RadioGroup className="pl-5 mb-4" name={question.key} id={question.key}>
        {question.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </Grid>
);
const RenderTextAreaQuestion = ({ question }) => {
  return (
    <Grid item xs={12} style={{ marginBottom: 20 }}>
      <FormControl component="fieldset" className="w-4/5 lg:w-3/">
        <FormLabel component="legend">{question.question}</FormLabel>
        <TextField
          multiline
          fullWidth
          name={question.key}
          variant="outlined"
          rows={4}
          className="mb-4 w-3/4"
        />
      </FormControl>
    </Grid>
  );
};
const RenderClientField = ({ question }) => {
  const clientService = new ClientService();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const fetchClients = async () => {
    //** It filters by backend role. For this reason, no filtering was done here.
    const {
      data: { data: clients },
    } = await clientService.getAll();
    setClients(clients);
  };

  const handleChange = (event) => {
    const selectedClient = clients.find(
      (client) => client.id === event.target.value
    );
    if (selectedClient) {
      setSelectedClient(selectedClient);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchClients();
    })();
  }, []);
  return (
    <Grid item xs={12} style={{ marginBottom: 20 }}>
      <FormControl component="fieldset" className="w-4/5 lg:w-3/">
        <FormLabel component="legend">{question.question}</FormLabel>
        <Select name="client" onChange={handleChange} required={true}>
          {clients.map((client) => (
            <MenuItem key={client.id} value={client.id}>
              {client.full_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedClient && (
        <input
          type="hidden"
          name="client_name"
          value={selectedClient.full_name}
        />
      )}
    </Grid>
  );
};
const RenderVolunteerField = ({ question, formKey }) => {
  const authService = new AuthService();
  const userService = new UserService();
  const currentUser = authService.getUserData();
  const isVolunteer = currentUser.roles.includes("volunteer");
  const [volunteers, setVolunteers] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const fetchVolunteer = async () => {
    const {
      data: { data: volunteers },
    } = await userService.getAll({
      custom: "&roles=volunteer",
    });
    setVolunteers(volunteers);
  };

  const handleChange = (event) => {
    const selectedVolunteer = volunteers.find(
      (volunteer) => volunteer.id === event.target.value
    );
    if (selectedVolunteer) {
      setSelectedVolunteer(selectedVolunteer);
    }
  };

  useEffect(() => {
    if (!isVolunteer) {
      (async () => {
        await fetchVolunteer();
      })();
    }
  }, []);

  if (isVolunteer) {
    return (
      <>
        <input type="hidden" name="volunteer" value={currentUser.userInfo.id} />
        <input
          type="hidden"
          name="volunteer_name"
          value={currentUser.userInfo.full_name}
        />
        <input
          type="hidden"
          name="interviewer"
          value={currentUser.userInfo.id}
        />
        <input
          type="hidden"
          name="interviewer_name"
          value={currentUser.userInfo.full_name}
        />
      </>
    );
  } else if (
    question.interviewer === true &&
    (formKey === "volunteer_final" || formKey === "volunteer_6months")
  ) {
    return (
      <>
        <input
          type="hidden"
          name="interviewer"
          value={currentUser.userInfo.id}
        />
        <input
          type="hidden"
          name="interviewer_name"
          value={currentUser.userInfo.full_name}
        />
      </>
    );
  } else {
    return (
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <FormControl component="fieldset" className="w-4/5 lg:w-3/">
          <FormLabel component="legend">{question.question}</FormLabel>
          <Select name="volunteer" onChange={handleChange} required={true}>
            {volunteers.map((volunteer) => (
              <MenuItem key={volunteer.id} value={volunteer.id}>
                {volunteer.full_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedVolunteer && (
          <input
            type="hidden"
            name="volunteer_name"
            value={selectedVolunteer.full_name}
          />
        )}
      </Grid>
    );
  }
};

const FormSpreeForm = ({ surveyKey }) => {
  const formKey = surveyKey;

  const [state, handleSubmit] = useForm(formKey, {
    debug: true,
  });

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const history = useHistory();

  const arrayedFields = questions.forms[formKey].fields;

  const questionnaire = [];
  Object.entries(arrayedFields).forEach(([key, value]) => {
    questionnaire.push({ key, ...value });
  });

  useEffect(() => {
    if (state.succeeded) {
      clearNotifications();
      setNotifications([`Küsimustik on edukalt salvestatud`]);
      setNotificationVariant("success");
      history.push("/dashboard/");
    }
  }, [state.succeeded]);

  if (!questions) return <p>Laen küsimustikku</p>;
  return (
    <>
      <Typography mb={4} sx={{ fontSize: "20px" }}>
        {questions.forms[formKey].name}
      </Typography>
      <Divider sx={{ mb: 4 }}></Divider>
      <form onSubmit={handleSubmit}>
        {questionnaire &&
          questionnaire.map((question, index) => {
            return (
              <React.Fragment key={index}>
                {question.render_type === "checkbox" && (
                  <RenderCheckboxQuestion question={question} />
                )}
                {question.render_type === "radio" && (
                  <RenderRadioQuestion question={question} />
                )}
                {question.render_type === "text" && (
                  <RenderTextAreaQuestion question={question} />
                )}
                {question.render_type === "client" && (
                  <RenderClientField question={question} />
                )}
                {question.render_type === "volunteer" && (
                  <RenderVolunteerField question={question} formKey={formKey} />
                )}
                {/* {question.customTextArea && question.customTextArea.status && (
                  <TextField
                    key={index}
                    label={question.customTextArea.label}
                    multiline
                    fullWidth
                    name={`${question.key}c`}
                    variant="standard"
                  />
                )} */}
              </React.Fragment>
            );
          })}
        <Grid container spacing={3} flex justifyContent={"center"}>
          <Grid item xs={4}>
            <Button type="submit" fullWidth variant="contained" color="success">
              Salvesta küsimustik
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormSpreeForm;
