import { useEffect, useMemo, useState } from "react";
import ReportsFilter from "../../components/Reports/ReportsFilter";
import ReportsGenerate from "../../components/Reports/ReportsGenerate";
import Table from "../../components/Table";
import ReportService from "../../services/ReportService";

const Datatable = () => {
  const reportService = new ReportService();

  const [reports, setReports] = useState([]);

  const fetchReports = () => {
    reportService
      .getAll({
        includes: ["creatorUser", "county", "localGovernment"],
        sorts: ["-id"],
      })
      .then((res) => {
        /*const _reports = res.data.data.map(report => {
                  return {
                  }
              })*/
        setReports(res.data.data);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Kuupäev",
        accessor: "created_at",
      },
      {
        Header: "Raporti tüüp",
        accessor: "report_type",
      },
      {
        Header: "Alguse kuupäev",
        accessor: "start_date",
      },
      {
        Header: "Lõpukuupäev",
        accessor: "end_date",
      },
      {
        Header: "Kohalik omavalitsus",
        accessor: "local_government.name",
      },
      {
        Header: "Maakond",
        accessor: "county.name",
      },
      {
        Header: "Isikud",
        accessor: "persons",
      },
      {
        Header: "Raporti looja",
        accessor: "creator_user.full_name",
      },
      {
        Header: "Lae alla",
        accessor: "temporary_url",
      },
    ],
    []
  );

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="container py-5">
      <div className="py-10">
        <h2>Genereeri raportid</h2>
        <p>Täisraporti genereerimiseks jäta väljad tühjaks</p>
        <ReportsGenerate fetchReports={fetchReports} />
      </div>
      <ReportsFilter />
      <Table data={reports} columns={columns} />
    </div>
  );
};

export default Datatable;
