import axios from "axios";
import { getApiUrl } from "./utils";

export default class JwtService {
  client = axios;

  config = {
    baseURL: getApiUrl(),
    tokenKey: "accessToken",
    userDataKey: "userData",
    tokenType: "Bearer",
  };

  constructor() {
    // ** Request Interceptor
    this.client.interceptors.request.use((config) => {
      config.baseURL = this.config.baseURL;
      // ** Get token from localStorage
      const accessToken = this.getToken();

      // ** If token is present add it to request's Authorization Header
      if (accessToken) {
        config.headers.Authorization = `${this.config.tokenType} ${accessToken}`;
      }
      return config;
    });
  }

  getToken() {
    return localStorage.getItem(this.config.tokenKey);
  }

  setToken(token) {
    localStorage.setItem(this.config.tokenKey, token);
  }

  removeToken() {
    localStorage.removeItem(this.config.tokenKey);
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.config.userDataKey));
  }

  setUserData(userData) {
    localStorage.setItem(this.config.userDataKey, JSON.stringify(userData));
  }

  removeUserData() {
    localStorage.removeItem(this.config.userDataKey);
  }

  isAuthenticated() {
    return !!this.getToken();
  }
}
