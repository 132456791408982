import axios from "axios";
import { useState } from "react";

export const useFormspree = () => {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const getSubmissions = async (hash, api) => {
    const formspreeApi = axios.create({
      baseURL: "https://formspree.io/api/0/forms/",
      headers: {
        Authorization: `Bearer ${api}`,
      },
    });

    try {
      setLoading(true);
      const response = await formspreeApi.get(`/${hash}/submissions`);
      setData(response.data.submissions);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return { data, loading, getSubmissions };
};
