import React, { useEffect, useState } from "react";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import LocalGovernmentService from "../../services/LocalGovernmentService";
import { useNotification } from "../../context/errorContext";
import InputSelect from "../Form/InputSelect";
import CountyService from "../../services/CountyService";

function LocalGovernmentForm({ isEdit, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const history = useHistory();
  const localGovernmentService = new LocalGovernmentService();
  const countyService = new CountyService();
  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const [counties, setCounties] = useState([]);

  const submitForm = (data) => {
    if (isEdit) {
      localGovernmentService
        .update(id, data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Kohalik omavalitsus ${data.name} on uuendatud`]);
          setNotificationVariant("success");
          history.push("/local_governments");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      localGovernmentService
        .create(data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Kohalik omavalitsus ${data.name} on loodud`]);
          setNotificationVariant("success");
          history.push("/local_governments");
        })
        .catch((error) => {
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchLocalGovernment = (id) => {
    localGovernmentService
      .getSingle(id)
      .then((res) => {
        let fields = res.data.data;

        setValue("name", fields.name);
        setValue("county_id", fields.county_id);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/local_governments");
        }
      });
  };

  const fetchCounties = () => {
    countyService
      .getAll()
      .then((res) => {
        const items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
        setCounties(items);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchLocalGovernment(id);
    }
  }, [id]);

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <InputCard title="Kohalikud omavalitsused">
            <Input
              label="Kohaliku omavalitsuse nimi"
              id="name"
              register={register}
              required
              error={errors}
            />
            <div className="py-3" />
            <InputSelect
              label="Maakond"
              id="county_id"
              register={register}
              required
              error={errors}
              options={counties}
            />
          </InputCard>
        </Card>
        <hr className="py-5" />
        <div className="flex justify-center py-5 space-x-4">
          <button className="bg-green-400 py-4 px-6" type="submit">
            Salvesta
          </button>
          <button className="bg-red-400 py-4 px-6">Katkesta</button>
        </div>
      </form>
    </div>
  );
}

export default LocalGovernmentForm;
