import { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { Query } from "../../helpers/querybuilder";
import { Button, Container, Grid } from "@mui/material";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import "moment/locale/et";

const ClientsWorksheet = () => {
  moment.locale("et");
  const { data, loading, fetchData } = useFetch();

  const [clients, setClients] = useState(null);

  const fetchClients = () => {
    const query = new Query();
    const url = query
      .for("clients")
      .select("id", "name", "last_name", "personal_id", "date_of_birth")
      .params({ qb: "true" })
      .get();

    fetchData(url);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const filteredData = data?.map((client) => {
      const { name, last_name, personal_id, date_of_birth } = client;
      return {
        name: name + " " + last_name,
        personal_id: personal_id || moment(date_of_birth).format("L"),
      };
    });

    setClients(filteredData);
  }, [data]);

  const header = ["Nimi", "Isikukood"];

  const handleDownloadExcel = () => {
    const currentDate = moment().format("YY_MM_DD");

    downloadExcel({
      fileName: `${currentDate}_abivajajad.xls`,
      sheet: "Abivajajad",
      tablePayload: {
        header,
        // accept two different data structures
        body: clients,
      },
    });
  };

  if (loading) return <p>Laen nimekirja...</p>;

  return (
    <div>
      <Container>
        <Grid paddingY={5}>
          <Button variant="contained" onClick={handleDownloadExcel}>
            Lae vabatahtlike nimekiri alla
          </Button>
        </Grid>
        <Grid>
          <table>
            <thead>
              <tr>
                <td className="w-60 border-1">Nimi</td>
                <td>Isikukood</td>
              </tr>
            </thead>
            <tbody>
              {clients.map((client, i) => {
                return (
                  <tr key={i}>
                    <td>{client.name}</td>
                    <td>
                      {client.personal_id ||
                        moment(client.date_of_birth).format("L")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
      </Container>
    </div>
  );
};

export default ClientsWorksheet;
