import React from "react";
import { useForm } from "react-hook-form";
import ButtonSubmit from "../../components/ButtonSubmit";
import InputTextarea from "../../components/Form/InputTextarea";

const VolunteerSemiannual = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="container py-5">
        <form onSubmit={handleSubmit(submitForm)}>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Kas ja kuidas on Teie algsed ootused vabatahtliku töö osas
              täitunud?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q1"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Kuidas on vabatahtlik tegevus mõjutanud Teie igapäevast elu?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                small="võib suunata täpsustavate küsimustega mõtlema järgmistele aspektidele: suhted ja kontaktid,  teadmised ja oskused, kogemused ja õppetunnid, enesekindlus, motivatsioon, enesetunne, füüsiline ja emotsionaalne tervis, ajakasutus, positsioon kogukonnas, mõju karjäärile või tööle saamise võimalustele jne; "
                id="q2"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              • Mis on Teid vabatahtliku tegevuse juures kõige enam rõõmustanud?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q3"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>Milliseid probleeme on esinenud?</h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q4"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              • Kas ja kuidas on Teie soov vabatahtlikuna tegutseda muutunud?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q5"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>Millised on Teie peamised ootused järgmisele kuuele kuule?</h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q6"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>Millist toetust ootate meilt?</h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q7"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Mida võiksime vabatahtlike tegevuse korraldamise juures muuta?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                small="võib suunata täpsustavate küsimustega mõtlema järgmistele aspektidele: suhted ja kontaktid,  teadmised ja oskused, kogemused ja õppetunnid, enesekindlus, motivatsioon, enesetunne, füüsiline ja emotsionaalne tervis, ajakasutus, positsioon kogukonnas, mõju karjäärile või tööle saamise võimalustele jne; "
                id="q8"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Kas soovitaksite vabatahtlikuks hakkamist mõnele oma tuttavale?
            </h4>
            <div className="py-4 w-3/4">
              <InputTextarea
                rows="4"
                id="q9"
                register={register}
                required
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>

          <ButtonSubmit
            text="Esita küsitluse andmed"
            type="submit"
          ></ButtonSubmit>
        </form>
      </div>
    </>
  );
};

export default VolunteerSemiannual;
