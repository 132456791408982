import { queryBuilder } from "./utils";
import { AxiosInstance } from "axios";
import JwtService from "./JwtService";

export default class ApiService extends JwtService {
  /**
   * @type {string}
   */
  collection = "";
  /**
   * @type {AxiosInstance}
   */
  client;

  /**
   * @type {{}}
   */
  globalFilters = {};

  /**
   * @type {[]}
   */
  globalSorts = [];

  /**
   * @type {[]}
   */
  globalIncludes = [];

  /**
   * @type {[]}
   */
  globalAppends = [];

  /**
   * @type {{}}
   */
  globalFields = {};

  constructor() {
    super();
  }

  /**
   * @param QBConfig {{}}
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAll = async (QBConfig = {}) => {
    this.checkCollection();

    let url = `/api/${this.collection}`;
    QBConfig = this.getQBResult(QBConfig);
    url += `?${queryBuilder(QBConfig)}`;

    try {
      const response = await this.client.get(url);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        this.clearLocalStorage();
        window.location.href = "/login";
      }
      throw error;
    }
  };

  /**
   * @param id
   * @param QBConfig
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSingle = async (id, QBConfig = {}) => {
    this.checkCollection();

    let url = `/api/${this.collection}/${id}`;
    QBConfig = this.getQBResult(QBConfig);
    url += `?${queryBuilder(QBConfig)}`;

    try {
      const response = await this.client.get(url);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.clearLocalStorage();
        window.location.href = "/login";
      }
      throw error;
    }
  };

  /**
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  create = async (data) => {
    this.checkCollection();

    try {
      const response = await this.client.post(`/api/${this.collection}`, data);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.clearLocalStorage();
        window.location.href = "/login";
      }
      throw error;
    }
  };

  /**
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  update = async (id, data) => {
    this.checkCollection();

    try {
      const response = await this.client.put(
        `/api/${this.collection}/${id}`,
        data
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.clearLocalStorage();
        window.location.href = "/login";
      }
      throw error;
    }
  };

  /**
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  destroy = async (id) => {
    this.checkCollection();

    try {
      const response = await this.client.delete(
        `/api/${this.collection}/${id}`
      );
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.clearLocalStorage();
        window.location.href = "/login";
      }
      throw error;
    }
  };

  getQBResult = (QBConfig) => {
    QBConfig.filters = { ...(QBConfig.filters || {}), ...this.globalFilters };
    QBConfig.sorts = [...(QBConfig.sorts || []), ...this.globalSorts];
    QBConfig.includes = [...(QBConfig.includes || []), ...this.globalIncludes];
    QBConfig.appends = [...(QBConfig.appends || []), ...this.globalAppends];
    QBConfig.fields = { ...(QBConfig.fields || {}), ...this.globalFields };
    return QBConfig;
  };

  checkCollection = () => {
    if (this.collection.trim().length < 1)
      throw new Error("Should specify collection name!");
  };

  clearLocalStorage = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
  };
}
