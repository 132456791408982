import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export const usePostData = (url) => {
  const token = localStorage.getItem("accessToken");

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
    withCredentials: true,
    xsrfHeaderName: "X-XSRF-TOKEN",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  });

  return useMutation((data) => api.post(url, data), {
    onError: (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        throw error;
      }
    },
  });
};
