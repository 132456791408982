import React, { useState, useEffect } from "react";
import Profile from "../../components/Profile";
import { useParams, useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import Button from "../../components/Button";
import { useNotification } from "../../context/errorContext";
import { roleCheck } from "../../services/utils";
import AuthService from "../../services/AuthService";

const VolunteerSingle = () => {
  const { id } = useParams();

  const [currentVolunteer, setCurrentVolunteer] = useState(null);
  const authService = new AuthService();
  const userService = new UserService();

  const history = useHistory();
  const user = authService.getUserData();

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const fetchVolunteer = (id) => {
    userService
      .getSingle(id, {
        includes: ["roles"],
      })
      .then((res) => {
        setCurrentVolunteer(res.data.data);
      })
      .catch((error) => {});
  };

  const destroyVolunteer = (id) => {
    userService
      .destroy(id)
      .then(() => {
        clearNotifications();
        setNotifications([
          `Abivajaja ${user.name} ${user.last_name} on kustutatud`,
        ]);
        history.push("/volunteers");
      })
      .catch((error) => {
        console.error(error);
        clearNotifications();
      });
  };

  useEffect(() => {
    fetchVolunteer(id);
  }, []);

  return (
    <div className="container py-10">
      {currentVolunteer && (
        <>
          <div className="flex justify-between">
            <h2>{currentVolunteer.full_name}</h2>
            {roleCheck(user.roles, ["super_admin", "admin"]) && (
              <div>
                <button
                  className="py-4 px-6 bg-red-400"
                  onClick={() => destroyVolunteer(id)}
                >
                  Kustuta abivajaja
                </button>
              </div>
            )}
          </div>

          <p>ID: {id}</p>
          <p>Isikukood: {currentVolunteer.personal_id}</p>
          <hr className="py-5" />
          <Button href={`/volunteers/${id}/edit`} cta="Muuda andmeid" />
          <hr className="my-5" />
          <Profile profileData={currentVolunteer} />
        </>
      )}
    </div>
  );
};

export default VolunteerSingle;
