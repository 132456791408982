import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import UserService from "../../services/UserService";
import PermissionService from "../../services/PermissionService";
import CountyService from "../../services/CountyService";
import LocalGovernmentService from "../../services/LocalGovernmentService";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import InputSelect from "../Form/InputSelect";

import InputTextarea from "../Form/InputTextarea";
import { useNotification } from "../../context/errorContext";
import { generatePassword } from "../../helpers/passwordGenerator";

function VolunteerForm({ isEdit, isAddVolunteer = true, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const history = useHistory();
  const userService = new UserService();
  const permissionService = new PermissionService();
  const localGovernmentService = new LocalGovernmentService();
  const countyService = new CountyService();

  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const [roles, setRoles] = useState([]);
  const [localGovernments, setLocalGovernments] = useState([]);
  const [counties, setCounties] = useState([]);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!isEdit) {
      setGeneratedPassword(generatePassword());
    }
  }, []);

  useEffect(() => {
    setValue("password", generatedPassword);
    setValue("password_confirmation", generatedPassword);
  }, [generatedPassword]);

  useEffect(() => {
    if (isAddVolunteer) {
      register("role", { value: "volunteer" });
    }
  }, []);

  const submitForm = (data) => {
    setSubmitting(true);
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === "convicted_url") {
        if (value.length > 0) {
          formData.append("convicted_url", value[0]);
        }
      } else if (key === "cooperationAgreement" && value.length > 0) {
        formData.append("cooperationAgreement", value[0]);
      } else {
        formData.append(key, value);
      }
    }

    formData.append("form_type", isAddVolunteer ? "volunteer" : "super_admin");

    if (isEdit) {
      userService
        .update(id, formData)
        .then((res) => {
          let data = res.data.data;
          clearNotifications();
          setNotifications([
            `${!isAddVolunteer ? "Kasutaja" : "Vabatahtlik"} ${data.name} ${
              data.last_name
            } on edukalt värskendatud`,
          ]);
          setNotificationVariant("success");
          let redirectLocation = `/${
            isAddVolunteer ? "volunteers" : "super_admins"
          }`;
          setSubmitting(false);
          history.push(redirectLocation);
        })
        .catch((error) => {
          setSubmitting(false);
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      userService
        .create(formData)
        .then((response) => {
          clearNotifications();
          setSubmitting(false);
          setNotifications([
            `${!isAddVolunteer ? "Kasutaja" : "Vabatahtlik"} ${data.name} ${
              data.last_name
            } on edukalt lisatud`,
          ]);
          setNotificationVariant("success");
          let redirectLocation = `/${
            isAddVolunteer ? "volunteers" : "super_admins"
          }`;
          history.push(redirectLocation);
        })
        .catch((error) => {
          setSubmitting(false);
          console.error("Something went wrong!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchRoles = () => {
    permissionService
      .getRoles()
      .then((res) => {
        const _roles = res.data.data.map((item) => {
          return {
            value: item.name,
            text: item.name,
          };
        });
        setRoles(_roles);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchLocalGovernments = () => {
    localGovernmentService
      .getAll({
        includes: ["county"],
        filters: {
          by_responsible_counties_with_coordinator_role: [true],
        },
      })
      .then((res) => {
        let items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: `${item.county.name} / ${item.name}`,
          };
        });
        setLocalGovernments(items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCounties = () => {
    countyService
      .getAll({})
      .then((res) => {
        let items = res.data.data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
        setCounties(items);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchVolunteer = (id) => {
    userService
      .getSingle(id, {
        includes: ["roles", "cooperationAgreement"],
      })
      .then((res) => {
        let fields = res.data.data;
        setValue("name", fields.name);
        setValue("last_name", fields.last_name);
        setValue("email", fields.email);
        setValue("application", fields.application);
        setValue("local_government_id", fields.local_government_id);
        setValue("participated_in_courses", fields.participated_in_courses);
        setValue("personal_id", fields.personal_id);
        setValue("expectations", fields.expectations);
        setValue(
          "role",
          fields.roles.map((role) => role.name)
        );
        setValue("telephone", fields.telephone);
        setValue("application", fields.application);
        setValue("expectations", fields.expectations);
        setValue(
          "responsible_county_ids",
          fields.responsible_county_ids.join(",")
        );
        if (fields.convicted_url) {
          setConvictedUrl(fields.convicted_url);
        }

        if (fields.convicted_temporary_url) {
          setConvictedUrl(fields.convicted_temporary_url);
        }
        if (fields.cooperation_agreement_temporary_url) {
          setCooperationAgreementUrl(
            fields.cooperation_agreement_temporary_url
          );
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          history.push("/volunteers");
        }
      });
  };

  useEffect(() => {
    fetchRoles();
    fetchLocalGovernments();
    fetchCounties();
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchVolunteer(id);
    }
  }, [id, localGovernments]);

  // Check if file is added
  const [convictedUrlName, setConvictedUrl] = useState(null);
  const [cooperationAgreementUrl, setCooperationAgreementUrl] = useState(null);

  useEffect(() => {
    if (isAddVolunteer && getValues("convicted_url").length) {
      console.error("Emin");
      setConvictedUrl(getValues("convicted_url")[0].name);
    }
  });

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <InputCard
            title="Isikuandmed"
            description={`Siselda tärniga märgitud isikuandmed uue ${
              isAddVolunteer ? "vabatahtliku" : "kasutaja"
            } lisamiseks`}
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Eesnimi"
                  id="name"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Eesnimi on vajalik"
                />
              </div>
              <div>
                <Input
                  label="Perekonnanimi"
                  id="last_name"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Perenmi on vajalik"
                />
              </div>
              <div>
                <Input
                  label="E-posti aadress"
                  id="email"
                  type="email"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Perenmi on vajalik"
                  // pattern={
                  //   /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                  // }
                  patternMessage="Vigane e-mail"
                />
              </div>
              <div>
                <Input
                  label="Isikukood"
                  id="personal_id"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Isikukood on puudu või vigane"
                  // pattern={/^[1-6][0-9]{2}[0-1][0-9][0-9]{2}[0-9]{4}$/}
                  patternMessage="Isikukood on vigane"
                />
              </div>

              <div className="w-1/2">
                <Input
                  label="Telefon"
                  id="telephone"
                  type="tel"
                  className="w-1/2"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Telefon on vajalik väli"
                />
              </div>

              <div className="w-1/2">
                <InputSelect
                  label="Kohalik omavalitsus"
                  id="local_government_id"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Kohalik omavalitsus on vajalik väli"
                  options={localGovernments}
                />
              </div>

              {isAddVolunteer && (
                <div className="w-1/2">
                  {/* <InputFile
                    title="Karistusregistri väljavõte"
                    id="convicted_url"
                    register={register}
                    fileUrlName={convictedUrlName}
                  /> */}
                  <h4 className="text-xl my-4">Karistusregistri väljavõte</h4>
                  {convictedUrlName && (
                    <>
                      <p className="text-green-700">
                        Karistusregistri väljavõte on üles laetud
                      </p>
                      <p className="mb-4">
                        Soovi korral võid uue väljavõtte lisada:
                      </p>
                    </>
                  )}
                  <input
                    type="file"
                    name="convicted_url"
                    id="convicted_url"
                    {...register("convicted_url")}
                  />
                </div>
              )}

              {isAddVolunteer && (
                <div className="w-1/2">
                  {/* <InputFile
                    title="Karistusregistri väljavõte"
                    id="convicted_url"
                    register={register}
                    fileUrlName={convictedUrlName}
                  /> */}
                  <h4 className="text-xl my-4">Vabatahtliku koostööleping</h4>
                  {cooperationAgreementUrl && (
                    <>
                      <p className="text-green-700">
                        Vabatahtliku koostööleping on üles laetud
                      </p>
                      <p className="mb-4">
                        Soovi korral võid uue lepingu lisada:
                      </p>
                    </>
                  )}
                  <input
                    type="file"
                    name="cooperationAgreement"
                    id="cooperationAgreement"
                    {...register("cooperationAgreement")}
                  />
                </div>
              )}
            </div>
          </InputCard>
        </Card>
        <hr className="py-5" />
        <Card>
          <InputCard
            title="Parool"
            description="Sisesta parool ja selle kinnitus"
          >
            <Input
              label="Parool"
              id="password"
              type="password"
              className="w-1/2"
              register={register}
              required={!isEdit}
              error={errors}
              errorMessage="Parool on kohustuslik"
            />
            <Input
              label="Parooli kinnitus"
              id="password_confirmation"
              type="password"
              className="w-1/2"
              register={register}
              required={!isEdit}
              error={errors}
              errorMessage="Parooli kinnitus on kohustuslik"
            />
            <div className="p-2 mt-4 border rounded">
              <p>Genereertitud parool: {generatedPassword}</p>
            </div>
          </InputCard>
        </Card>
        {isAddVolunteer && (
          <>
            <hr className="py-5" />
            <Card>
              <InputCard
                title="Sooviavaldus"
                description="Kirjuta vabatahtliku sooviavaldus"
              >
                <InputTextarea
                  id="application"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Sooviavaldus on vajalik väli"
                />
              </InputCard>
            </Card>

            <hr className="py-5" />
            <Card>
              <InputCard
                title="Ootused"
                description="Kirjelda vabatahtliku ootusi"
              >
                <InputTextarea
                  id="expectations"
                  register={register}
                  required
                  error={errors}
                  errorMessage="Ootused on vajalik väli"
                />
              </InputCard>
            </Card>
            <hr className="py-5" />
            <Card>
              <InputCard
                title="Vabatahtliku osalemine kursustel"
                description="Vabatahtlike kursustel osalemise koolitus"
              >
                <div>
                  <InputSelect
                    label="Osales koolitusel"
                    id="participated_in_courses"
                    options={[
                      { value: false, text: "Ei" },
                      { value: true, text: "Jah" },
                    ]}
                    register={register}
                    required
                    error={errors}
                    errorMessage="Koolitusel osalemine on vajalik väli"
                  />
                </div>
              </InputCard>
            </Card>
          </>
        )}

        <hr className="py-5" />
        {!isAddVolunteer && (
          <div>
            <Card>
              <InputCard title="Kasutaja roll">
                <div>
                  <InputSelect
                    label="Roll"
                    id="role"
                    options={roles}
                    emptyOption={false}
                    register={register}
                    required
                    isMultiple={true}
                    error={errors}
                    errorMessage=""
                  />
                </div>
              </InputCard>
            </Card>
            <hr className="py-5" />
            <Card>
              <InputCard
                title="Seotud maakonnad"
                description="NB! Seotud maakonnad rakenduvad ainult koordinaatori kasutajagrupile"
              >
                <div>
                  <InputSelect
                    label="Seotud maakonnad"
                    id="responsible_county_ids"
                    options={counties}
                    register={register}
                    required
                    error={errors}
                    errorMessage=""
                    isMultiple={true}
                  />
                </div>
              </InputCard>
            </Card>
          </div>
        )}

        <div className="flex justify-center py-5 space-x-4">
          <button
            className="bg-green-400 py-4 px-6 disabled:text-gray-400 disabled:bg-green-300"
            type="submit"
            disabled={submitting}
          >
            Salvesta
          </button>
          <button
            className="bg-red-400 py-4 px-6"
            onClick={() => history.push("/volunteers")}
          >
            Katkesta
          </button>
        </div>
      </form>
    </div>
  );
}

export default VolunteerForm;
