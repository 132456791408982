import React from 'react';
import ClientForm from "../../components/PagesForm/ClientForm";

function ClientAdd() {
    return (
        <ClientForm
            isEdit={false}
        />
    );
}

export default ClientAdd;