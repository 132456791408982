import React, { useState, useEffect } from "react";
import Profile from "../../components/Profile";
import { useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import Button from "../../components/Button";

const UserSingle = () => {
  const { id } = useParams();

  const [currentVolunteer, setCurrentVolunteer] = useState(null);
  const userService = new UserService();

  const fetchVolunteer = (id) => {
    userService
      .getSingle(id, {
        includes: ["roles"],
      })
      .then((res) => {
        setCurrentVolunteer(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchVolunteer(id);
  }, []);

  return (
    <div className="container py-10">
      {currentVolunteer && (
        <>
          <h2>{currentVolunteer.full_name}</h2>
          <p>ID: {id}</p>
          <p>Isikukood: {currentVolunteer.personal_id}</p>
          <hr className="py-5" />
          <Button href={`/volunteers/${id}/edit`} cta="Muuda andmeid" />
          <hr className="my-5" />
          <Profile profileData={currentVolunteer} />
        </>
      )}
    </div>
  );
};

export default UserSingle;
