import React, { useEffect, useState } from "react";
import Card from "../Card";
import InputCard from "../Form/InputCard";
import Input from "../Form/Input";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useNotification } from "../../context/errorContext";
import VisitPriceService from "../../services/VisitPriceService";

function CountyForm({ isEdit, id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const history = useHistory();
  const visitPriceService = new VisitPriceService();
  const { setNotifications, setNotificationVariant, clearNotifications } =
    useNotification();

  const submitForm = (data) => {
    if (isEdit) {
      visitPriceService
        .update(id, data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Uus hind loodud`]);
          setNotificationVariant("success");
          history.push("/visit_prices");
        })
        .catch((error) => {
          console.error("Viga!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    } else {
      visitPriceService
        .create(data)
        .then((res) => {
          clearNotifications();
          setNotifications([`Uus hind loodud`]);
          setNotificationVariant("success");
          history.push("/visit_prices");
        })
        .catch((error) => {
          console.error("Viga!", error.response);
          clearNotifications();
          setNotifications(error.response.data.errors);
          setNotificationVariant("danger");
        });
    }
  };

  const fetchVisitPrice = (id) => {
    visitPriceService
      .getSingle(id)
      .then((res) => {
        let fields = res.data.data;

        setValue("beginning_date", fields.beginning_date);
        setValue("price", fields.price);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/visit_prices");
        }
      });
  };

  useEffect(() => {
    if (isEdit) {
      fetchVisitPrice(id);
    }
  }, [id]);

  return (
    <div className="container py-10">
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <InputCard title="Külastuse hind">
            <Input
              label="Alguskuupäev"
              id="beginning_date"
              register={register}
              required
              error={errors}
              type="date"
            />
            <Input
              label="Hind"
              id="price"
              register={register}
              required
              error={errors}
              type="number"
            />
          </InputCard>
        </Card>
        <hr className="py-5" />
        <div className="flex justify-center py-5 space-x-4">
          <button className="bg-green-400 py-4 px-6" type="submit">
            Salvesta
          </button>
          <button className="bg-red-400 py-4 px-6">Katkesta</button>
        </div>
      </form>
    </div>
  );
}

export default CountyForm;
