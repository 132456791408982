import { useContext } from "react";
import ApiService from "./ApiService";
import { AuthContext } from "../context/AuthContext";

export default class AuthService extends ApiService {
  async login(email, password) {
    let url = "/api/oauth/login-password-grant";
    let data = {
      email,
      password,
    };
    const response = await this.client.post(url, data);
    this.setToken(response.data.access_token);
    await this.profile();
    return response;
  }

  async profile() {
    let url = "/api/user";
    const response = await this.client.get(url);
    const user = response.data;
    this.setUserData(user);
    return response;
  }

  async logout() {
    let url = "/api/oauth/logout";
    const response = await this.client.delete(url);
    this.removeToken();
    this.removeUserData();
    return response.status === 200;
  }
}
