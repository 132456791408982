import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import CountyService from "../../services/CountyService";

const Counties = () => {
  const [counties, setCounties] = useState([]);
  const countyService = new CountyService();

  const fetchCounties = () => {
    countyService
      .getAll()
      .then((response) => {
        setCounties(response.data.data);
      })
      .catch((error) => {
        console.error("Something went wrong!", error.response);
      });
  };

  const removeCounty = (id) => {
    countyService
      .destroy(id)
      .then(() => fetchCounties())
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCounties();
  }, []);

  return (
    <div className="container py-5">
      <h2>Counties</h2>
      <hr />
      <div className="justify-between flex flex-wrap py-2">
        <Button cta="Add County" href="/counties/add" />
      </div>

      <table className="table-fixed w-full">
        <thead>
          <tr className="bg-indigo-500 p-4">
            <th>Nimi</th>
            <th>Muuda</th>
            <th>Kustuta</th>
          </tr>
        </thead>
        <tbody>
          {counties &&
            counties.map((county) => {
              return (
                <tr className="hover:bg-indigo-100" key={county.id}>
                  <td className="p-2 border">{county.name}</td>
                  <td className="p-2 border text-right">
                    <Link to={`/counties/${county.id}/edit`}> Muuda</Link>
                  </td>
                  <td className="p-2 border text-right">
                    <button
                      className="bg-red-500 p-2 text-white"
                      onClick={() => removeCounty(county.id)}
                    >
                      Kustuta
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Counties;
