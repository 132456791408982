import React from "react";

const InputCheckbox = ({
  label,
  id,
  disabled,
  register,
  required,
  error = "",
  value = "test",
  errorMessage = "",
  small = "",
  checked = false,
    answerMode = false
}) => {
  let additionalProps = {};
  if (answerMode) {
    additionalProps.checked = checked
  }
  return (
    <div className="flex gap-x-4 mb-2">
      <input
        type="checkbox"
        name={id}
        id={id}
        disabled={disabled}
        value={value}
        {...register(id, { required, message: errorMessage })}
        className={`mt-1 ${
          error[id] && "border-red-500"
        } form-checkbox w-5 h-5 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md`}
        {...additionalProps}
      />
      <div>
        <label
          htmlFor={id}
          className={`block text-sm font-medium text-gray-700 ${
            disabled && "opacity-50"
          }`}
        >
          {label}
        </label>
        {small && <small className="text-gray-600">{small}</small>}
      </div>

      {error[id] && <small className="text-red-500">{errorMessage}</small>}
    </div>
  );
};

export default InputCheckbox;
