import React from "react";
import { useForm } from "react-hook-form";
import InputCheckbox from "../../components/Form/InputCheckbox";
import InputTextarea from "../../components/Form/InputTextarea";
import InputRadio from "../../components/Form/InputRadio";
import ButtonSubmit from "../../components/ButtonSubmit";

const ClientSemiannual = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();

  const expectationValues = [
    { value: "Täitusid täiesti", text: "Täitusid täiesti" },
    { value: "Täitusid osaliselt", text: "Täitusid osaliselt" },
    { value: "Ei täitunud", text: "Ei täitunud" },
    {
      value: "Ma ei oodanudki, et midagi paremaks ",
      text: "Ma ei oodanudki, et midagi paremaks ",
    },
  ];

  const submitForm = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="container py-5">
        <form onSubmit={handleSubmit(submitForm)}>
          <fieldset className="grid grid-cols-1 mb-5">
            <div>
              <h4 className="mb-2">
                Kuidas on saadud teenused parandanud Teie toimetulekut?
                (valikvastused, sh võimalus valida mitu valikut korraga)
              </h4>
            </div>
            <InputCheckbox
              label="parandanud enesetunnet;"
              value="Parandas enesetunnet"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <InputCheckbox
              label="parandanud võimet tulla iseseisvalt toime koduse igapäevaeluga või asjaajamistega väljaspool kodu;"
              value="parandanud võimet tulla iseseisvalt toime koduse igapäevaeluga või asjaajamistega väljaspool kodu"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
              small="iseseisvalt söömine; voodisse heitmine ja sealt tõusmine; riietumine; tualetis käimine; ravimite võtmine; poes käimine; majapidamistööd nagu koristamine, pesupesemine, voodi tegemine, triikimine, telefoni kasutamine; asjaajamine nagu ametiasutustes, pangas, valimas käimine või mõni muu tegevus, mida loetelus ei käsitleta);"
            />
            <InputCheckbox
              label="aidanud kaasa hariduse omandamisele, enesetäiendamisele;;"
              value="aidanud kaasa hariduse omandamisele, enesetäiendamisele;"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <InputCheckbox
              label="aidanud kaasa huvialadega, hobidega tegelemisele;"
              value="aidanud kaasa huvialadega, hobidega tegelemisele;"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <InputCheckbox
              label="loonud paremad võimalused osalemiseks kogukonnas;"
              value="loonud paremad võimalused osalemiseks kogukonnas;"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <InputCheckbox
              label="võimaldanud jätkata elamist koduses keskkonnas; "
              value="võimaldanud jätkata elamist koduses keskkonnas; "
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <InputCheckbox
              label="Aidanud jõuda vajaliku teenuseni või spetsialisti abini"
              value="Aidanud jõuda vajaliku teenuseni või spetsialisti abini"
              id="q1"
              type="checkbox"
              register={register}
              error=""
              errorMessage=""
            />
            <div className="w-3/4">
              <InputTextarea
                label="muul viisil, kuidas?"
                rows="4"
                id="q1a"
                register={register}
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>

          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Mil määral Teie esialgsed ootused oma toimetuleku paranemise osas
              täitusid? (valikvastused)
            </h4>
            <InputRadio
              id="q2"
              items={expectationValues}
              register={register}
              required
              error
            />
            <div className="w-3/4">
              <InputTextarea
                label="(valikuline)"
                rows="4"
                id="q2a"
                register={register}
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>
              Mil määral Teie esialgsed ootused vabatahtliku abi osas täitusid?
            </h4>
            <InputRadio
              id="q3"
              items={expectationValues}
              register={register}
              required
              error
            />
            <div className="w-3/4">
              <InputTextarea
                label="(valikuline)"
                rows="4"
                id="q3a"
                register={register}
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>Kuidas hindate senist koostööd vabatahtlikuga? </h4>
            <InputRadio
              id="q4"
              items={[
                { value: "See on suurepärane", text: "See on suurepärane" },
                { value: "Olen väga rahul", text: "Olen väga rahul" },
                {
                  value: "Võib jääda enam-vähem rahule",
                  text: "Võib jääda enam-vähem rahule",
                },
                { value: "Ma ei ole rahul", text: "Ma ei ole rahul" },
                {
                  value: "Ma ei ole üldse rahul",
                  text: "Ma ei ole üldse rahul",
                },
              ]}
              register={register}
              required
              error
            />
            <div className="w-3/4">
              <InputTextarea
                label="(valikuline)"
                rows="4"
                id="q4a"
                register={register}
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-1 mb-5">
            <h4>Mida tuleks Teie hinnangul muuta senise koostöö osas? </h4>
            <div className="w-3/4">
              <InputTextarea
                label="(valikuline)"
                rows="4"
                id="q5"
                register={register}
                error=""
                errorMessage=""
              />
            </div>
          </fieldset>
          <ButtonSubmit
            text="Esita küsitluse andmed"
            type="submit"
          ></ButtonSubmit>
        </form>
      </div>
    </>
  );
};

export default ClientSemiannual;
