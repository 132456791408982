import React from 'react';
import ClientForm from "../../components/PagesForm/ClientForm";
import {useParams} from "react-router-dom";

function ClientEdit(props) {
    const {id} = useParams()
    return (
        <ClientForm
        isEdit={true}
        id={id}
        />
    );
}

export default ClientEdit;