import React from "react";
import { useTable, useFilters } from "react-table";
import { convertDate } from "../../helpers/date";
import { getReportTypeText } from "../../services/utils";

const Table = ({ mode, columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <table className="table-fixed w-full" {...getTableProps}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="bg-indigo-500 p-4"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="py-2">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === "id")
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 border text-right"
                    >
                      <a href={`/${mode}/${cell.value}`}>Vaata</a> /{" "}
                      <a href={`/${mode}/${cell.value}/edit`}>Muuda</a>
                    </td>
                  );
                if (
                  cell.column.id ===
                  ("beginning_of_activities") || cell.column.id === ('end_of_activities')
                )
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 border text-right"
                    >
                      {cell.value ? convertDate(cell.value) : ""}
                    </td>
                  );
                if (cell.column.id === "temporary_url")
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 border text-right"
                    >
                      <a href={cell.value} target="_blank">
                        Lae alla
                      </a>
                    </td>
                  );
                if (cell.column.id === "report_type")
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="p-2 border text-right"
                    >
                      <a href={cell.value} target="_blank">
                        {getReportTypeText(cell.value)}
                      </a>
                    </td>
                  );
                return (
                  <td {...cell.getCellProps()} className="p-2 border">
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
