import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../components/Form/Input";
import { usePostData } from "../hooks/usePostData"; // Ensure this import path is correct.
import { Alert } from "@mui/material";

const ForgotPassword = () => {
  const history = useHistory();
  const { mutate } = usePostData("/password/forgot-password");
  const [notification, setNotification] = useState(null);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleForgotPassword = () => {
    const email = getValues("email");
    mutate(
      { email },
      {
        onSuccess: (response) => {
          setNotification({
            variant: "success",
            message: response.data.data.sent_message,
          });
        },
        onError: (error) => {
          setNotification({
            variant: "error",
            message: error.response.data.message,
          });
        },
      }
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {notification && (
        <Alert severity={notification.variant}>{notification.message}</Alert>
      )}
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sisesta oma e-maili aadress ning parool saadetakse postkasti
          </h2>
        </div>

        <form
          className="mt-8 space-y-6"
          onSubmit={handleSubmit(handleForgotPassword)}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <Input
                type="email"
                id="email"
                label="Email address"
                register={register}
                required
                error={errors}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              Saada uus parool
            </button>
          </div>
        </form>

        <div className="text-sm">
          <button
            onClick={() => history.push("/login")}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Logi sisse
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
