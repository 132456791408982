import { useState, createContext, useContext } from "react";

const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(null);
  const [notificationVariant, setNotificationVariant] = useState(null);

  const notificationHandler = (messages) => {
    setNotifications(messages);
  };

  const clearNotifications = () => {
    setNotifications(null);
    setNotificationVariant(null);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        notificationVariant,
        setNotificationVariant,
        clearNotifications,
        notificationHandler,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  return useContext(NotificationContext);
}
