import CustomRouting from "./CustomRouting";
import { NotificationContextProvider } from "./context/errorContext";
import { AuthProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NotificationContextProvider>
          <CustomRouting />
        </NotificationContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
