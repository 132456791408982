import React from "react";
import el_logo from "../assets/images/el_sotsiaalfond_horisontaalne.jpg";
import kodukant_logo from "../assets/images/kodukant_logo.png";

const Logos = () => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="grid md:grid-cols-2 gap-4 md:gap-16">
        <div>
          <img
            className="md:w-auto md:h-24"
            src={el_logo}
            alt="EL Sotsiaalfond"
          ></img>
        </div>
        <div>
          <img
            className="md:w-auto md:h-24"
            src={kodukant_logo}
            alt="Kodukant"
          ></img>
        </div>
      </div>
      <p>
        Teenust rahastatakse: ESF TAT “Tööturul osalemist toetavad
        hoolekandeteenused” SFOS 2014-2020.2.02.001.01.15-0002.
        Struktuurivahendite indentifitseerimise number: Grant kood 1T10-hoolek,
        wbs T10-hoolek-153
      </p>
    </div>
  );
};

export default Logos;
