import ApiService from "./ApiService";

export default class ClientService extends ApiService {
  collection = "clients";

  globalIncludes = ["localGovernment", "localGovernment.county"];

  /**
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  update = async (id, data) => {
    this.checkCollection();

    return await this.client.post(`/api/${this.collection}/${id}`, data);
  };
}
