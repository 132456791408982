import React from "react";
import { Link } from "react-router-dom";

const DashboardAction = ({ name, route }) => {
  return (
    <div className="p-8 border-2 border-indigo-900 h-auto justify-center align-center text-center flex flex-col relative">
      <span className="icon-codepen text-5xl text-indigo-800 mb-6"/>
        <Link to={route} className="stretched-link">{name}</Link>
    </div>
  );
};

export default DashboardAction;
