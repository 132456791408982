import React from "react";
import VisitPriceForm from "../../components/PagesForm/VisitPriceForm";
import {useParams} from "react-router-dom";
const VisitPriceEdit = () => {
    const {id} = useParams()
    return (
        <VisitPriceForm
            isEdit={true}
            id={id}
        />
    );
};

export default VisitPriceEdit;
